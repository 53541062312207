import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  errorMsg = '';

  constructor(
    public auth: AuthService,
    private router: Router,
    private db: AngularFirestore
  ) {
  }

  onLogout(): void {
    this.auth.logout();
    this.router.navigate(['/', 'login']);
  }

  onInstillinger(): void {
    this.router.navigate(['/', 'instillinger'])
  }

  sendError(): void {
    console.log('Cought error: Attempting to send error report to DB');
    try {
      const uid = this.auth.uid || 'no_user';
      const d = new Date().toISOString();
      this.db.collection(`/error/${uid}/report`).doc(d).set({
        message: this.errorMsg,
      }).then(res => {
        console.log(`Error reported to: /error/${uid}/report/${d}`);
      }).catch(err => {
        throw err;
      });
      this.errorMsg = '';
    } catch (e) {
      console.error('Failed to send error report to db:', e.message);
    }
  }
}
