import { Injectable } from "@angular/core";
import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { Klasse } from "../models/klasse";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { AuthService } from "../auth/auth.service";
import { ProverService } from "../prover/prover.service";
import { InstillingerService } from "../instillinger/instillinger.service";

@Injectable({
  providedIn: "root",
})
export class KlasserService {
  loading = true;
  list: Klasse[] = [];
  private klasseSubject = new BehaviorSubject<Klasse[]>(undefined);
  private klasserSubscription: Subscription;
  private klasserCollection: AngularFirestoreCollection;

  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
    private prover: ProverService,
    private instillinger: InstillingerService
  ) {
    // Runs every time uid is updated
    auth.user$.subscribe((user) => {
      // Clear current firestore connection data and clear klasse in prover-service
      this.list.splice(0, this.list.length);
      this.loading = true;

      if (this.klasserCollection) {
        this.klasserCollection = undefined;
      }

      if (this.klasserSubscription) {
        this.klasserSubscription.unsubscribe();
        this.klasserSubscription = undefined;
      }

      this.klasseSubject.next(undefined);

      this.prover.setKlasse(null);

      // Return if uid == null, aka logged out
      if (!user) {
        return;
      }

      // Update the collection and subscription
      this.klasserCollection = this.db.collection("klasser", (ref) =>
        ref.where("owner", "==", user.uid)
      );
      this.klasserSubscription = this.klasserCollection
        .stateChanges()
        .subscribe((snaps) => this.stateChange(snaps));
    });
  }

  // State change callback, for firebase, to manage the klasser-array (this.list)
  private stateChange(snaps: any): void {
    // Looping trough the snaps
    for (const snap of snaps) {
      // Adds new klasse to klasser-array, if type == added
      if (snap.type === "added") {
        this.list.push(
          new Klasse({
            id: snap.payload.doc.id,
            ...snap.payload.doc.data(),
          })
        );

        // Updates klasse, if type == modified
      } else if (snap.type === "modified") {
        for (const klasse of this.list) {
          if (klasse.id === snap.payload.doc.id) {
            klasse.update(snap.payload.doc.data());
            break;
          }
        }

        // Removes klasse from klasser-array, if type == removed
      } else if (snap.type === "removed") {
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].id === snap.payload.doc.id) {
            this.list.splice(i, 1);
            break;
          }
        }
      }
    }

    this.klasseSubject.next(this.list); // Send the new list to the klasse subscriptions
    this.loading = false;
  }

  // Create subscription on a spicific klasse
  klasse$(id: string): Observable<Klasse> {
    return this.klasseSubject.pipe(
      map(
        (list): Klasse => {
          // Return undefined if the service is currently loading klasser
          if (list === undefined) {
            return undefined;
          }

          // Loop trough the list of klasser
          for (const klasse of list) {
            if (klasse.id === id) {
              return klasse;
            }
          }

          // Return null if the klasse does not exist
          return null;
        }
      )
    );
  }

  // Add new klasse to the collection
  newKlasse(name: string): Promise<firebase.firestore.DocumentReference> {
    // Return if the user is not logged in
    if (!this.auth.uid) {
      return;
    }

    return this.klasserCollection.add({
      year: this.instillinger.global.thisYear,
      owner: this.auth.uid,
      name,
    });
  }

  // Delete klasse from collection
  deleteKlasse(klasse: Klasse): void {
    // Return if klasse is undefined or user is not logged in
    if (!klasse || !this.auth.uid) {
      return;
    }

    this.klasserCollection.doc(klasse.id).delete();
  }

  // Update the list of students of a certain klasse
  updateStudents(klasse: Klasse): void {
    this.klasserCollection.doc(klasse.id).update({
      students: klasse.students,
      nextStudentId: klasse.nextStudentId,
    });
  }

  // Update the name of a certain klasse
  updateName(klasse: Klasse): void {
    this.klasserCollection.doc(klasse.id).update({ name: klasse.name });
  }

  // Update the grades of a certain class
  updateKarakterer(klasse: Klasse): void {
    this.klasserCollection.doc(klasse.id).update({
      halvaarsvurdering: klasse.halvaarsvurdering,
      sluttvurdering: klasse.sluttvurdering,
      eksamenskarakter: klasse.eksamenskarakter,
    });
  }

  getScores(elevId: number) {
    elevId = Number(elevId);
    let result: any[] = this.prover.list.map((prove) => {
      let result = {
        anbefaltKarakter: "",
        prove: prove,
        canView: prove.openToElev.map(val => Number(val)).includes(elevId),
        ...prove.getScore(elevId),
      };
      return result;
    });

    for (let prove of result) {
      let karakterSkala = Object.entries(
        this.prover.getKarakterskala(prove.prove)
      )
        .map((v) => ({ key: v[0], value: v[1] }))
        .sort((a: any, b: any) => b.value - a.value);
      if (prove.prove.hasParticipated(elevId))
        prove.anbefaltKarakter = karakterSkala[karakterSkala.length - 1].key;
      else
        prove.anbefaltKarakter = "";
      for (let e of karakterSkala) {
        if (prove.total > e.value) {
          prove.anbefaltKarakter = e.key;
          break;
        }
      }
    }

    return result;
  }

  delMedElev(klasse: Klasse, elevId?: number): Promise<any> {
    return new Promise((res, rej) => {
      let elevIds =
        elevId || elevId === 0 ? [elevId] : Object.keys(klasse.students);

      console.log('elever:', elevIds);
      this.db
        .collection(`elev`, (ref) => ref.where("klasse", "==", klasse.id))
        .get()
        .subscribe(async (elm) => {
          let elevDict = elm.docs.reduce(
            (res, doc) =>
              Object.assign(res, {
                [doc.data().elev]: doc.id,
              }),
            {}
          );

          for (let elevId of elevIds) {
            elevId = Number(elevId);
            let scores = this.getScores(elevId);
            const elev: any = {};
            elev.elev = elevId;
            elev.klasse = klasse.id;
            elev.prover = [];

            for (const score of scores) {
              if (score.canView)
                elev.prover.push({
                  name: score.prove.name,
                  id: score.prove.id,
                  egenvurdering: score.prove.egenvurdering.map((e) => e.text),
                  egenretting: score.prove.egenretting,
                  score: {
                    del1: score.del1,
                    del1Oppgaver: score.del1Oppgaver,
                    del2: score.del2,
                    del2Oppgaver: score.del2Oppgaver,
                    kommentar: score.kommentar || "",
                    total: score.total,
                    karakter:
                      score.karakter && score.karakter != ""
                        ? score.karakter
                        : score.anbefaltKarakter,
                  },
                });
            }

            if (elevDict[elevId]) {
              await this.db.doc(`/elev/${elevDict[elevId]}`).update(elev);
            } else {
              let elevRes = await this.db.collection('elev').add(elev);
              elevDict[elevId] = elevRes.id;
            }
          }

          res(elevDict);
        });
    });
  }
}
