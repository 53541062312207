import { Component, AfterContentInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { Elev } from "../models/elev";
import { Prove } from '../models/prove';
import { toNum } from '../utils';

declare function chartElevGen(p: any);

@Component({
  selector: "app-elev-view",
  templateUrl: "./elev-view.component.html",
  styleUrls: ["./elev-view.component.scss"],
})
export class ElevViewComponent implements AfterContentInit {
  elev: any;
  canView = false;

  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngAfterContentInit() {
    this.route.params.subscribe((params) => {
      this.db
        .doc(`elev/${params.id}`)
        .get()
        .subscribe((elev) => {
          this.elev = elev.data();

          this.db
            .collection(`elev/${elev.id}/egenvurdering`)
            .get()
            .subscribe((egenvurderinger) => {
              this.db
                .collection(`elev/${elev.id}/egenretting`)
                .get()
                .subscribe((egenrettinger) => {
                  let vurderinger = egenvurderinger.docs.map((e) => e.id);
                  let rettinger = egenrettinger.docs.map((e) => e.id);

                  for (const prove of this.elev.prover) {
                    if (
                      prove.egenvurdering &&
                      prove.egenvurdering.length != 0 &&
                      !vurderinger.includes(prove.name)
                    ) {
                      this.router.navigate([
                        "elev",
                        params.id,
                        "egenvurdering",
                        prove.name,
                      ]);
                      return;
                    }

                    if (
                      prove.egenretting &&
                      !rettinger.includes(prove.name)
                    ) {
                      this.router.navigate([
                        "elev",
                        params.id,
                        "egenvurdering",
                        prove.name,
                      ]);
                      return;
                    }
                  }

                  egenrettinger.forEach(doc => {
                    this.elev.prover.forEach(prove => {
                      if (prove.name == doc.id) {
                        prove.egenretting = doc.data();
                      }
                    })
                  });

                  setTimeout(() => {
                    this.updateChart();
                  }, 0);

                  this.canView = true;
                });
            });
        });
    });
  }

  updateChart() {
    chartElevGen(
      this.elev.prover.map((prove) => ({
        name: prove.name,
        del1: prove.score.del1,
        del2: prove.score.del2,
        total: prove.score.total,
      }))
    );
  }

  evalueringFerdig(prove: any): boolean {
    if (!this) return false;

    if (prove.egenvurdering.length == 0) return true;

    if (this.elev.egenvurdering[prove.id]) return true;

    return false;
  }

  getColor(num: any, den: any): string {
    if (num === '')
      return 'none';

    num = toNum(num);
    den = toNum(den);

    if (!den)
      return 'none';

    const dec = num  / den;
    const r = Math.min((1 - dec) * 512, 256);
    const g = Math.min(dec * 512, 256);

    return `rgb(${r},${g},100)`;
  }
}
