<div class="container">
  <!-- LOADING SPINNER -->
  <div class="row mt-3">
    <div class="col-12">
      <button class="btn btn-primary" (click)="goBack()">Tilbake</button>
      <app-elev-share [scores]="scores"></app-elev-share>

      <div *ngIf="elev.klasse == null" class="ra-spinner mt-3">
        <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="elev.klasse != null">
    <!-- ELEV NAME -->
    <div class="col-12 elev-title">
      <ng-template #titleView>
        <h1 style="text-align: center;">
          {{ elev.klasse.name }}: {{ elev.name }}
        </h1>
        <i
          class="fa fa-edit fa-2x text-secondary ml-3"
          (click)="onEditName()"
        ></i>
      </ng-template>

      <div class="input-group input-group-lg" *ngIf="editName; else titleView">
        <input
          type="text"
          id="edit-name"
          class="form-control"
          [(ngModel)]="elev.name"
          (focusout)="onSaveName()"
          (keyup.enter)="onSaveName()"
        />
      </div>
    </div>

    <div class="col-12">
      <h2>Karakterer:</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Prøve</th>
            <th scope="col">Del 1</th>
            <th scope="col">Del 2</th>
            <th scope="col">Total</th>
            <th scope="col">Karakter</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let prove of scores">
            <td>{{ prove.name }}</td>
            <td>{{ prove.del1 }}%</td>
            <td>{{ prove.del2 }}%</td>
            <td>{{ prove.total }}%</td>
            <td>
              <input
                type="text"
                class="form-control karakter-input"
                [placeholder]="prove.anbefaltKarakter"
                [(ngModel)]="prove.karakter"
                (focusout)="updateKarakter(prove)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-12 chart-container">
      <div class="chart-box">
        <canvas id="chart-elev"></canvas>
      </div>
    </div>

    <div class="col-12">
      <h2>Egenvurderingssvar:</h2>

      <div *ngFor="let prove of egenvurderinger" class="mt-4">
        <h3>{{ prove.name }}:</h3>
        <div *ngFor="let s of prove.val">
          <h4>{{ s.sporsmaal }}</h4>
          <p>{{ s.svar }}</p>
        </div>
        <div *ngIf="egenrettinger[prove.name]">
          <strong>Del 1: </strong><br />
          <table class="poeng-table">
            <thead>
              <tr>
                <th scope="row">Oppgave</th>
                <th
                  scope="col"
                  *ngFor="let oppgave of egenrettinger[prove.name].oppgaverDel1"
                >
                  {{ oppgave.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Maks Poeng</th>
                <td
                  *ngFor="let oppgave of egenrettinger[prove.name].oppgaverDel1"
                >
                  {{ oppgave.max }}
                </td>
              </tr>
              <tr>
                <th scope="row">Egenretting</th>
                <td
                  *ngFor="
                    let svar of egenrettinger[prove.name].del1;
                    let i = index
                  "
                  [style.background-color]="
                    getColor(
                      svar,
                      egenrettinger[prove.name].oppgaverDel1[i].max
                    )
                  "
                >
                  {{ svar }}
                </td>
              </tr>
              <tr>
                <th scope="row">Resultat</th>
                <td
                  *ngFor="let oppgave of egenrettinger[prove.name].oppgaverDel1"
                  [style.background-color]="
                    getColor(oppgave.value, oppgave.max)
                  "
                >
                  {{ oppgave.value }}
                </td>
              </tr>
            </tbody>
          </table>
          <strong>Del 2: </strong><br />
          <table class="poeng-table">
            <thead>
              <tr>
                <th scope="row">Oppgave</th>
                <th
                  scope="col"
                  *ngFor="let oppgave of egenrettinger[prove.name].oppgaverDel2"
                >
                  {{ oppgave.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Maks Poeng</th>
                <td
                  *ngFor="let oppgave of egenrettinger[prove.name].oppgaverDel2"
                >
                  {{ oppgave.max }}
                </td>
              </tr>
              <tr>
                <th scope="row">Egenretting</th>
                <td
                  *ngFor="let svar of egenrettinger[prove.name].del2; let i = index;"
                  [style.background-color]="
                    getColor(
                      svar,
                      egenrettinger[prove.name].oppgaverDel2[i].max
                    )
                  "
                >
                  {{ svar }}
                </td>
              </tr>
              <tr>
                <th scope="row">Resultat</th>
                <td
                  *ngFor="let oppgave of egenrettinger[prove.name].oppgaverDel2"
                  [style.background-color]="
                    getColor(oppgave.value, oppgave.max)
                  "
                >
                  {{ oppgave.value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
