import { Component, OnInit, Input } from '@angular/core';
import { ElevService } from '../../elev.service';
import { ProverService } from 'src/app/prover/prover.service';
import { Elev } from '../../../models/elev';
import { AngularFirestore } from '@angular/fire/firestore';
import { KlasserService } from 'src/app/klasser/klasser.service';

declare function $(p: any): any;

@Component({
  selector: 'app-elev-share',
  templateUrl: './elev-share.component.html',
  styleUrls: ['./elev-share.component.scss']
})
export class ElevShareComponent implements OnInit {

  @Input() scores;
  link: string;
  sub_link: string;
  loading = false;

  constructor(
    public elev: ElevService,
    private prover: ProverService,
    private klasser: KlasserService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
  }

  openModal() {
    $('#share-modal').modal('show');
  }

  async onSave() {

    this.loading = true;

    for (const score of this.scores) {
      score.prove.setElevCanView(this.elev.elevId, score.canView);
      await this.prover.pushChanges(score.prove);
    }

    let elevDict = await this.klasser.delMedElev(this.elev.klasse, this.elev.elevId)

    this.link = 'https://retteskjema.no/elev/' + elevDict[this.elev.elevId];
    this.sub_link = '/elev/' + elevDict[this.elev.elevId];

    this.loading = false;
  }
}
