import { kMaxLength } from 'buffer';

export class User {

  id: string;
  active = false;
  fullName: string = null;
  admin = false;

  constructor(id: string, obj?: any) {
    this.id = id;
    if (typeof obj === 'object') {
      if (typeof obj.active === 'boolean')
        this.active = obj.active;
      if (typeof obj.fullName === 'string')
        this.fullName = obj.fullName;
      if (typeof obj.admin === 'boolean')
        this.admin = obj.admin;
    }
  }

  toObject(): Object {
    let res: any = {};
    Object.assign(res, this);
    delete res.id;
    return res;
  }
}