import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Prove } from 'src/app/models/prove';
import { ProverService } from '../../prover.service';
import { SpreadSheetComponent, SpreadSheetData } from 'src/app/misc/spread-sheet/spread-sheet.component';
import { InstillingerService } from 'src/app/instillinger/instillinger.service';

declare function $(params: any): any;

@Component({
  selector: 'app-prove-settings',
  templateUrl: './prove-settings.component.html',
  styleUrls: ['./prove-settings.component.scss']
})
export class ProveSettingsComponent implements OnInit, AfterViewInit {

  @Input() prove: Prove;
  @Output() save = new EventEmitter<void>();

  @ViewChild(SpreadSheetComponent) proveOppgaverDel1: SpreadSheetComponent;
  @ViewChild(SpreadSheetComponent) proveOppgaverDel2: SpreadSheetComponent;

  karaktererRow1 = [
    "Seks",
    "5+",
    "Fem",
    "5-",
    "4+",
    "Fire",
    "4-",
    "3+"
  ];

  karaktererRow2 = [
    "Tre",
    "3-",
    "2+",
    "To",
    "2-",
    "1+",
    "En"
  ];

  constructor(
    public prover: ProverService,
    public instillinger: InstillingerService
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    $('#prove-settings-modal').on('hidden.bs.modal', () => {
      this.onSave();
    });
  }

  onOpenSettings() {

    if (!this.prove) {
      return;
    }

    this.proveOppgaverDel1.updateData();
    this.proveOppgaverDel2.updateData();

    $('#prove-settings-modal').modal('show');
  }

  onSave() {
    $('#prove-settings-modal').modal('hide');
    this.save.emit();
  }

  private oppgavePushRowDel1(i?: number) {
    this.prove.addOppgaveDel1('', 0, i);
  }

  private oppgavePopRowDel1(i: number) {
    this.prove.removeOppgaveDel1(i);
  }

  private oppgaveDataDel1(data: SpreadSheetData): SpreadSheetData {

    if (!this.prove) {
      return null;
    }

    if (data == null) {
      data = new SpreadSheetData();
      data.pushRow = (i?: number) => this.oppgavePushRowDel1(i);
      data.popRow = (i: number) => this.oppgavePopRowDel1(i);

      data.colHeads = [
        { text: 'Oppgave tittel', type: 'string' },
        { text: 'Max poengsum', type: 'number' }
      ];
    }


    data.rows = this.prove.oppgaverDel1
      .map(oppgave => ({row: [{obj: oppgave, key: 'name'}, {obj: oppgave, key: 'maxPoints'}]}));

    return data;
  }

  getOppgaveDataFunctionDel1() {
    return (data: SpreadSheetData) => this.oppgaveDataDel1(data);
  }

  private oppgavePushRowDel2(i?: number) {
    this.prove.addOppgaveDel2('', 0, i);
  }

  private oppgavePopRowDel2(i: number) {
    this.prove.removeOppgaveDel2(i);
  }

  private oppgaveDataDel2(data: SpreadSheetData): SpreadSheetData {

    if (!this.prove) {
      return null;
    }

    if (data == null) {
      data = new SpreadSheetData();
      data.pushRow = (i?: number) => this.oppgavePushRowDel2(i);
      data.popRow = (i: number) => this.oppgavePopRowDel2(i);

      data.colHeads = [
        { text: 'Oppgave tittel', type: 'string' },
        { text: 'Max poengsum', type: 'number' }
      ];
    }


    data.rows = this.prove.oppgaverDel2
      .map(oppgave => ({row: [{obj: oppgave, key: 'name'}, {obj: oppgave, key: 'maxPoints'}]}));

    return data;
  }

  getOppgaveDataFunctionDel2() {
    return (data: SpreadSheetData) => this.oppgaveDataDel2(data);
  }

  addSporsmaal() {
    this.prove.egenvurdering.push({text: ''});
  }

  dropSporsmaal(i) {
    console.log(i);
    this.prove.egenvurdering.splice(i, 1);
  }

  setEgenretting(val: boolean): void {
    this.prove.egenretting = val;
  }
}
