<div class="container">

  <div class="row mt-3">
    <div class="col-12">
      <button class="btn btn-primary mr-2" routerLink="../..">Tilbake</button>
      <button class="btn btn-success mr-2" (click)="save()">Lagre</button>

      <app-prove-settings *ngIf="klasse && prove" [prove]="prove" (save)="onSaveSettings()"></app-prove-settings>
      <button class="btn btn-danger float-right" *ngIf="klasse && prove" (click)="onDelete()">Slett prøven</button>

      <!-- LOADING SPINNER -->
      <div *ngIf="klasse === undefined || prove === undefined" class="ra-spinner mt-3">
        <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
      </div>

      <div *ngIf="klasse === null || prove === null" class="alert alert-danger mt-3" role="alert">
        Du har ikke tilgang til denne prøven
      </div>
    </div>
  </div>

  <div class="row mt-3" *ngIf="klasse && prove">

    <div class="col-12">
      <div class="d-flex justify-content-center prove-title">

        <h1 class="mr-2">{{klasse.name}}: </h1>

        <ng-template #titleView>
          <h1>{{prove.name}}</h1>

          <i class="fa fa-edit fa-2x text-secondary ml-3" (click)="onEditName()"></i>
        </ng-template>

        <div class="input-group input-group-lg" *ngIf="editName; else titleView">
          <input type="text" id="edit-name" class="form-control" [(ngModel)]="prove.name" (focusout)="onSaveName()"
            (keyup.enter)="onSaveName()">
        </div>
      </div>
    </div>
  </div>

</div>

<div class="d-flex justify-content-center">

  <table *ngIf="klasse && prove && students" class="retteskjema">
    <tr>
      <th></th>
      <th class="del-header" scope="col" [attr.colspan]="prove.oppgaverDel1.length" *ngIf="!!prove.oppgaverDel1.length">
        Del 1</th>
      <th class="del-header" scope="col" [attr.colspan]="prove.oppgaverDel2.length" *ngIf="!!prove.oppgaverDel2.length">
        Del 2</th>
      <th class="del-header" scope="col" colspan="6">Resultat</th>
    </tr>
    <tr>
      <th></th>
      <th scope="col" *ngFor="let oppgave of prove.oppgaverDel1; let j = index;" [class.focus]="j == focusJ">
        {{oppgave.name}} ({{oppgave.maxPoints}})</th>
      <th scope="col" *ngFor="let oppgave of prove.oppgaverDel2; let j = index;"
        [class.focus]="j + prove.oppgaverDel1.length == focusJ">{{oppgave.name}} ({{oppgave.maxPoints}})</th>
      <th scope="col" *ngIf="!!prove.oppgaverDel1.length">Del 1</th>
      <th scope="col" *ngIf="!!prove.oppgaverDel2.length">Del 2</th>
      <th scope="col" *ngIf="!!prove.oppgaverDel1.length || !!prove.oppgaverDel2.length">Totalt</th>
      <th scope="col">Karakter</th>
      <th scope="col">Lås rad</th>
      <th scope="col">Kommentar</th>
    </tr>
    <tr *ngFor="let student of students; let i = index;" [class.locked]="getLaas(student.id)">
      <th class="student-name" scope="row" [routerLink]="'../../elev/' + student.id" [class.focus]="i == focusI">
        {{ student.name }}</th>
      <td class="score-input" *ngFor="let oppgave of prove.oppgaverDel1; let j = index;" id="si-{{i}}-{{j}}"
        (keydown)="onKeydown(i, j, student, oppgave, 'del1', $event)" (dblclick)="onDbclick(i, j, student)" tabindex="0"
        [style.background-color]="getColor(oppgave.maxPoints, prove.scores[student.id].del1[oppgave.id])"
        (focusin)="onFocusin(i, j)"
        (focusout)="focusI = focusJ = -1">
        <div>
          <input *ngIf="i == editingI && j == editingJ; else notEditing" type="number"
            [(ngModel)]="prove.scores[student.id].del1[oppgave.id]"
            (focusout)="onFocusout('del1', oppgave.id, student.id)">
          <ng-template #notEditing>{{prove.scores[student.id].del1[oppgave.id]}}</ng-template>
        </div>
      </td>
      <td class="score-input" *ngFor="let oppgave of prove.oppgaverDel2; let j = index;"
        id="si-{{i}}-{{j + prove.oppgaverDel1.length}}"
        (keydown)="onKeydown(i, j + prove.oppgaverDel1.length, student, oppgave, 'del2', $event)"
        (dblclick)="onDbclick(i, j + prove.oppgaverDel1.length, student)" tabindex="0"
        [style.background-color]="getColor(oppgave.maxPoints, prove.scores[student.id].del2[oppgave.id])"
        (focusin)="onFocusin(i, j + prove.oppgaverDel1.length)"
        (focusout)="focusI = focusJ = -1">
        <div>
          <input *ngIf="i == editingI && j + prove.oppgaverDel1.length == editingJ; else notEditing2" type="number"
            [(ngModel)]="prove.scores[student.id].del2[oppgave.id]"
            (focusout)="onFocusout('del2', oppgave.id, student.id)">
          <ng-template #notEditing2>{{prove.scores[student.id].del2[oppgave.id]}}</ng-template>
        </div>
      </td>
      <td *ngIf="!!prove.oppgaverDel1.length">
        {{ getResultDel1(student.id) }}%
      </td>
      <td *ngIf="!!prove.oppgaverDel2.length">
        {{ getResultDel2(student.id) }}%
      </td>
      <td *ngIf="!!prove.oppgaverDel1.length || !!prove.oppgaverDel2.length">
        {{ getResult(student.id) }}%
      </td>
      <td>
        <input class="karakter-input" [placeholder]="getSugestedKarakter(student.id)"
          [value]="prove.karakterer[student.id] || ''" (focusout)="setKarakter(student.id, $event)">
      </td>
      <td>
        <input type="checkbox" [checked]="getLaas(student.id)" (click)="onLaas(student.id)">
      </td>
      <td width="300">
        <input type="text" class="kommentar" [(ngModel)]="prove.kommentar[student.id]">
      </td>
    </tr>
    <tr class="klasse-avg">
      <th class="student-name">Gjennomsnitt:</th>
      <td *ngFor="let oppgave of prove.oppgaverDel1"
        [style.background-color]="getColor(oppgave.maxPoints, getAvgDel1(oppgave))">
        {{ getAvgDel1(oppgave) }}
      </td>
      <td *ngFor="let oppgave of prove.oppgaverDel2"
        [style.background-color]="getColor(oppgave.maxPoints, getAvgDel2(oppgave))">
        {{ getAvgDel2(oppgave) }}
      </td>
      <td>
        {{ getAvgResultDel1() }} %
      </td>
      <td>
        {{ getAvgResultDel2() }} %
      </td>
      <td>
        {{ getAvgResult() }} %
      </td>
    </tr>
  </table>
</div>

<div class="container">

  <!-- DELETE PROVE MODAL -->
  <div class="modal fade" id="delete-prove-modal" *ngIf="klasse && prove">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-danger text-light">
          <h4>Slett prøve</h4>
        </div>
        <div class="modal-body">
          Er du sikker på at du vil slette prøven <span class="text-danger">{{prove.name}} i {{klasse.name}}</span>?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="onDeleteConfirm()">Slett {{prove.name}} i
            {{klasse.name}}</button>
        </div>
      </div>
    </div>
  </div>

</div>