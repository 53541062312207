import { Component, OnInit, OnDestroy } from '@angular/core';
import { KlasserService } from '../klasser.service';
import { Klasse } from 'src/app/models/klasse';
import { Prove } from 'src/app/models/prove';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { ProverService } from 'src/app/prover/prover.service';

declare function $(obj: any): any;

@Component({
  selector: 'app-klasser-single',
  templateUrl: './klasser-single.component.html',
  styleUrls: ['./klasser-single.component.scss']
})
export class KlasserSingleComponent implements OnDestroy {

  editName = false;

  newStudentName = '';
  deleteStudentId = -1;

  aar = '';
  newProveName = '';
  proveTilDeling: Prove = null;

  klasse: Klasse;
  private klasseSubscription: Subscription;

  elevdelingOversikt: any[] = null;

  constructor(
    private klasser: KlasserService,
    private router: Router,
    private db: AngularFirestore,
    public prover: ProverService,
    route: ActivatedRoute,
  ) {

    // Subscribe to router changes
    route.params.subscribe(params => {
      this.aar = params.aar;

      // Clear firestore data and clear prover-service
      this.klasse = undefined;
      this.prover.setKlasse(null);

      // Unsubscribe if subscription exists
      if (this.klasseSubscription) {
        this.klasseSubscription.unsubscribe();
        this.klasseSubscription = undefined;
      }

      // Subscribe to current klasse
      this.klasseSubscription = klasser.klasse$(params.klasseId).subscribe(klasse => {

        // Dont update if klasse is already set and new klasse is not null
        if (!klasse || !this.klasse) {
          this.klasse = klasse;
          this.prover.setKlasse(klasse);
        }
      });

    });
  }

  // Unsubscribe from subscription on deletion
  ngOnDestroy() {
    if (this.klasseSubscription) {
      this.klasseSubscription.unsubscribe();
      this.klasseSubscription = undefined;
    }
  }

  // Open delete klasse modal
  onDelete(): void {
    $('#delete-klasse-modal').modal('show');
  }

  // Delete klasse
  onDeleteConfirm(): void {
    $('#delete-klasse-modal').modal('hide');
    this.prover.setKlasse(null);
    this.klasser.deleteKlasse(this.klasse);
    this.router.navigate(['aar',this.aar,'klasser']);
  }

  // Open new student modal
  onNewStudent(): void {
    $('#add-student-modal').modal('show');
  }

  // Create new student specified by the new student modal
  onNewStudentConfirm(): void {
    $('#add-student-modal').modal('hide');

    if (!this.klasse || this.newStudentName === '' || Object.values(this.klasse.students).includes(this.newStudentName)) {
      return;
    }

    this.klasse.addStudent(this.newStudentName);
    this.newStudentName = '';
    this.klasser.updateStudents(this.klasse);
  }

  // Open new prove modal
  onNewProve(): void {
    $('#add-prove-modal').modal('show');
  }

  // Create new prove specified by the new prove modal
  onNewProveConfirm(): void {
    $('#add-prove-modal').modal('hide');

    if (!this.klasse || this.prover.loading) {
      return;
    }

    this.prover.newProve(this.newProveName);
    this.newProveName = '';
  }

  // Open delete student modal
  onDeleteStudent(studentId: number): void {

    this.deleteStudentId = studentId;
    $('#delete-student-modal').modal('show');
  }

  // Delete student specified by the delete student modal
  onDeleteStudentConfirm(): void {

    this.klasse.deleteStudent(this.deleteStudentId);
    this.klasser.updateStudents(this.klasse);

    this.deleteStudentId = -1;
    $('#delete-student-modal').modal('hide');
  }

  // Enable edit mode for the klasse name
  onEditName() {
    this.editName = true;
    setTimeout(() => {
      $('#edit-name').focus();
    }, 0);
  }

  // Save the new klasse name
  onSaveName() {
    this.editName = false;
    this.klasser.updateName(this.klasse);
  }

  onElevdelingOpen() {
    this.updateElevdelingOversit();
    $('#student-share-modal').modal('show');
  }

  updateElevdelingOversit() {
    this.elevdelingOversikt = null;
    this.db.collection(
      `elev`,
      ref => ref.where('klasse', '==', this.klasse.id)
    ).get().subscribe(res => {
      this.elevdelingOversikt = res.docs.map(doc => ({
        id: doc.id,
        elev: this.klasse.getStudentName(doc.data().elev),
        link: 'https://retteskjema.no/elev/' + doc.id,
        link_rel: '/elev/' + doc.id,
        prover: doc.data().prover.reduce((total, e) => {
          if (total === '')
            return e.name;
          return total + ', ' + e.name;
        }, ''),
      }));
    });
    this.klasser.delMedElev(this.klasse);
  }

  selectProveForDeling(prove: Prove): void {
    this.proveTilDeling = prove;
  }

  async delMedAlle() {
    if (!this.proveTilDeling)
      return;
    this.elevdelingOversikt = null;
    let prove = this.proveTilDeling;

    this.klasse.studentsAsArray().forEach(student => {
      prove.setElevCanView(student.id, true);
    });
    await this.prover.pushChanges(prove);
    await this.klasser.delMedElev(this.klasse);

    this.proveTilDeling = null;
    this.updateElevdelingOversit();
  }
}
