import { Component, OnInit, Input } from '@angular/core';
import { Key } from 'protractor';

declare function $(params: any): any;

export class SpreadSheetData {
  colHeads: { text?: string, type?: string, max?: number }[];

  rows: {

    row: {
      obj: any,
      key: string
    }[],

    head?: string,
    end?: (() => string)
  }[];

  pushRow: (i?: number) => void;
  popRow: (i: number) => void;
  background: (val: number, x: number, y: number) => string = (val, x, y) => '';
}

@Component({
  selector: 'app-spread-sheet',
  templateUrl: './spread-sheet.component.html',
  styleUrls: ['./spread-sheet.component.scss']
})
export class SpreadSheetComponent implements OnInit {

  editing = { x: -1, y: -1 };

  @Input() getData: (data: SpreadSheetData) => SpreadSheetData = null;
  @Input() prefix: string;

  data: SpreadSheetData = null;

  constructor() {
  }

  updateData() {
    this.data = this.getData(this.data);
  }

  ngOnInit() {
    this.updateData();
  }

  onKeydown(x: number, y: number, event: any) {

    if (this.editing.x !== -1) {
      return;
    }

    if (event.key.length === 1) {
      this.data.rows[y].row[x].obj[this.data.rows[y].row[x].key] = event.key;
      this.onDblclick(x, y);
      return;
    }

    switch (event.key) {

      case 'ArrowUp':
        if (y > 0) {
          $(`#${this.prefix}${x}-${y - 1}`).focus();
        }
        break;

      case 'ArrowDown':
        if (y + 1 < this.data.rows.length && x < this.data.rows[y + 1].row.length) {
          $(`#${this.prefix}${x}-${y + 1}`).focus();
        }
        break;

      case 'ArrowLeft':
        if (x > 0) {
          $(`#${this.prefix}${x - 1}-${y}`).focus();
        }
        break;

      case 'ArrowRight':
        if (x + 1 < this.data.rows[y].row.length) {
          $(`#${this.prefix}${x + 1}-${y}`).focus();
        }
        break;

      case 'Enter':
        $(`#${this.prefix}${x}-${y + (event.shiftKey ? -1 : 1)}`).focus();
        break;
    }
  }

  onDblclick(x: number, y: number) {
    this.editing = { x, y };
    setTimeout(() => {
      $(`#${this.prefix}${x}-${y}-input`).focus();
    }, 0);
  }

  onFocusout() {
    this.exitEditing();
  }

  onKeyupInput(x: number, y: number, event) {

    switch (event.key) {
      case 'Enter':
        this.exitEditing();
        setTimeout(() => {
          $(`#${this.prefix}${x}-${y + 1}`).focus();
        }, 0);
        break;
    }
  }

  exitEditing() {
    if (this.editing.x != -1 && this.editing.y != -1) {
      let val = this.data.rows[this.editing.y].row[this.editing.x].obj[this.editing.x];
      if (val !== '') {
        val = parseFloat(val);
        val = Math.max(val, 0);
        if (this.data.colHeads[this.editing.x].max)
          val = Math.min(val, this.data.colHeads[this.editing.x].max);
      }
      this.data.rows[this.editing.y].row[this.editing.x].obj[this.editing.x] = val;
    }
    this.editing.x = -1;
    this.editing.y = -1;
  }

  pushY(i?: number) {

    if (!this.data) {
      return;
    }

    this.data.pushRow(i);
    this.updateData();
  }

  popY(i: number) {

    if (!this.data) {
      return;
    }

    this.data.popRow(i);
    this.updateData();
  }

}
