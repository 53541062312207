
<div class="ra-wrapper">
  <div id="ra-nav" *ngIf="auth.isLoggedIn">
    <div id="ra-nav-first">
        <button class="ml-2 btn btn-outline-secondary dropdown-toggle mr-3" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Raporter en feil
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <textarea class="form-control" placeholder="Beskriv feilen" rows="7" cols="40" [(ngModel)]="errorMsg"></textarea>
          <a class="mt-2 btn btn-outline-secondary btn-block" (click)="sendError()">Send inn feilmelding</a>
        </div>
    </div>
    
    <div id="ra-nav-last">
      <div class="dropdown">
          <button class="btn btn-outline-secondary dropdown-toggle mr-3" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ auth.displayName }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="onInstillinger()">Instillinger</a>
            <a class="dropdown-item" (click)="onLogout()">Logg ut</a>
          </div>
        </div>
    </div>


  </div>
  <router-outlet></router-outlet>
</div>
