<div class="container">
  <ng-container *ngIf="elev && canView">
    <h1 class="mt-3 mb-3">Dine resultater</h1>

    <div class="col-12 chart-container">
      <div class="chart-box">
        <canvas id="chart-elev"></canvas>
      </div>
    </div>

    <div *ngFor="let prove of elev.prover" class="mb-3">
      <h2>{{ prove.name }}</h2>
      <div>
        <strong>Del 1: </strong><span>{{ prove.score.del1 }}%</span><br />
        <table class="poeng-table">
          <thead>
            <tr>
              <th scope="row">Oppgave</th>
              <th scope="col" *ngFor="let oppgave of prove.score.del1Oppgaver">
                {{ oppgave.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Maks Poeng</th>
              <td *ngFor="let oppgave of prove.score.del1Oppgaver">
                {{ oppgave.max }}
              </td>
            </tr>
            <tr *ngIf="prove.egenretting">
              <th scope="row">Din retting</th>
              <td
                *ngFor="let svar of prove.egenretting.del1; let i = index"
                [style.background-color]="
                  getColor(svar, prove.score.del1Oppgaver[i].max)
                "
              >
                {{ svar }}
              </td>
            </tr>
            <tr>
              <th scope="row">Dine resultater</th>
              <td
                *ngFor="let oppgave of prove.score.del1Oppgaver"
                [style.background-color]="getColor(oppgave.value, oppgave.max)"
              >
                {{ oppgave.value }}
              </td>
            </tr>
          </tbody>
        </table>
        <strong>Del 2: </strong><span>{{ prove.score.del2 }}%</span><br />
        <table class="poeng-table">
          <thead>
            <tr>
              <th scope="row">Oppgave</th>
              <th scope="col" *ngFor="let oppgave of prove.score.del2Oppgaver">
                {{ oppgave.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Maks Poeng</th>
              <td *ngFor="let oppgave of prove.score.del2Oppgaver">
                {{ oppgave.max }}
              </td>
            </tr>
            <tr *ngIf="prove.egenretting">
              <th scope="row">Din retting</th>
              <td
                *ngFor="let svar of prove.egenretting.del2; let i = index"
                [style.background-color]="
                  getColor(svar, prove.score.del2Oppgaver[i].max)
                "
              >
                {{ svar }}
              </td>
            </tr>
            <tr>
              <th scope="row">Dine resultater</th>
              <td
                *ngFor="let oppgave of prove.score.del2Oppgaver"
                [style.background-color]="getColor(oppgave.value, oppgave.max)"
              >
                {{ oppgave.value }}
              </td>
            </tr>
          </tbody>
        </table>
        <strong>Total: </strong><span>{{ prove.score.total }}%</span><br />
        <strong>Karakter: </strong><span>{{ prove.score.karakter }}</span
        ><br />
        <p><strong>Kommentar: </strong>{{ prove.score.kommentar || "" }}</p>
      </div>
    </div>
  </ng-container>
</div>
