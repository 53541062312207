<div class="container">

  <div class="row">
    <div class="col-lg-4 col-sm-3"></div>
    <div class="col-lg-4 col-sm-6  col-12">
      <h1 class="mt-5">Logg inn</h1>

      <!-- E-MAIL -->
      <div class="input-group mb-3 mt-5">
        <input type="text" class="form-control" placeholder="E-Post" [(ngModel)]="email"
          (keyup.enter)="passwordRef.focus()">
      </div>

      <!-- PASSORD -->
      <div class="input-group mb-3">
        <input type="password" class="form-control" placeholder="Passord" [(ngModel)]="password" #passwordRef
          (keyup.enter)="onLogin()">
      </div>

      <!-- LOGIN BUTTON -->
      <button class="btn btn-primary btn-block" [disabled]="email == '' || password == ''" (click)="onLogin()" #loginRef
        *ngIf="!loading">Logg inn</button>

      <!-- LOADING SPINNER -->
      <div *ngIf="loading" class="ra-spinner mb-2">
        <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
      </div>
    </div>
  </div>

</div>