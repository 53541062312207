import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { KlasserComponent } from './klasser/klasser/klasser.component';
import { AuthService } from './auth/auth.service';
import { KlasserSingleComponent } from './klasser/klasser-single/klasser-single.component';
import { ProveSingleComponent } from './prover/prove-single/prove-single.component';
import { InstillingerComponent } from './instillinger/instillinger.component';
import { ElevSingleComponent } from './elev/elev-single/elev-single.component';
import { AarComponent } from './aar/aar.component';
import { ElevViewComponent } from './elev-view/elev-view.component';
import { EgenvurderingComponent } from './elev-view/egenvurdering/egenvurdering.component';
import { YearSummaryComponent } from './year-summary/year-summary.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { RegistrationCompleteComponent } from './auth/registration/registration-complete/registration-complete.component';

const routes: Routes = [
  // NEEDS TO BE ON TOP IN ORDER TO PREVENT AUTH GUARD-LOOP
  {path: 'login', component: LoginComponent},
  {path: '', redirectTo: 'aar', pathMatch: 'full' },
  {path: 'aar', component: AarComponent, canActivate: [AuthService] },
  {path: 'aar/:aar/klasser', component: KlasserComponent, canActivate: [AuthService] },
  {path: 'aar/:aar/klasser/:klasseId', component: KlasserSingleComponent, canActivate: [AuthService] },
  {path: 'aar/:aar/klasser/:klasseId/prove/:proveId', component: ProveSingleComponent, canActivate: [AuthService] },
  {path: 'aar/:aar/klasser/:klasseId/elev/:elevId', component: ElevSingleComponent, canActivate: [AuthService] },
  {path: 'aar/:aar/klasser/:klasseId/oppsummering', component: YearSummaryComponent, canActivate: [AuthService] },
  {path: 'instillinger', component: InstillingerComponent, canActivate: [AuthService]},
  {path: 'elev/:id', component: ElevViewComponent },
  {path: 'elev/:id/egenvurdering/:prove', component: EgenvurderingComponent },
  {path: 'registration', component: RegistrationComponent },
  {path: 'registration/complete', component: RegistrationCompleteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
