import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Location } from '@angular/common';
import { ElevService } from '../elev.service';
import { ProverService } from '../../prover/prover.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { totalmem } from 'os';
import { toNum } from 'src/app/utils';
import { KlasserService } from 'src/app/klasser/klasser.service';

declare function $(param: any): any;
declare function chartElevGen(data: any): void;


@Component({
  selector: 'app-elev-single',
  templateUrl: './elev-single.component.html',
  styleUrls: ['./elev-single.component.scss']
})
export class ElevSingleComponent implements AfterContentInit{

  editName = false;
  scores: any[] = [];
  scoreDict: any = {};
  egenvurderinger: any[] = [];
  egenrettinger: any = {};

  constructor(
    public elev: ElevService,
    private prover: ProverService,
    private klasser: KlasserService,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private location: Location
  ) {

  }

  ngAfterContentInit() {
    this.route.params.subscribe((params) => {
      this.elev.use(params.klasseId, params.elevId)
      	.then(() => {
          this.scores = this.getScore();
          this.scoreDict = this.scores.reduce((obj, e) => {
            obj[e.name] = e;
            return obj;
          }, {});
          chartElevGen(this.scores);
          this.egenvurderinger = [];

          this.db.collection(
            `elev`,
            ref => ref.where('elev', '==', this.elev.elevId).where('klasse', '==', this.elev.klasse.id)
          ).get().subscribe(elm => {

            if (!elm.empty) {
              this.db.collection(`elev/${elm.docs[0].id}/egenvurdering`).get().subscribe(elm => {
                for (let e of elm.docs) {
                  let data = e.data();
                  this.egenvurderinger.push({
                    name: e.id,
                    val: Object.entries(data).reduce((total, e) => {
                      total.push({
                        sporsmaal: e[0],
                        svar: e[1]
                      });
                      return total;
                    }, [])
                  });
                }
              });

              this.db.collection(`elev/${elm.docs[0].id}/egenretting`).get().subscribe(elm => {
                for (let e of elm.docs) {
                  let data = e.data();
                  this.egenrettinger[e.id] = {
                    oppgaverDel1: this.scoreDict[e.id].del1Oppgaver,
                    oppgaverDel2: this.scoreDict[e.id].del2Oppgaver,
                    ...data
                  };
                }
              });
            }
          });
	    });
    });
  }

  onEditName() {
    this.editName = true;
    setTimeout(() => {
      $('#edit-name').focus();
    }, 0);
  }

  onSaveName() {
    this.editName = false;
    this.elev.saveName();
  }

  getScore() {
    return this.klasser.getScores(this.elev.elevId);
  }

  updateKarakter(prove) {
    if (!prove.karakter || prove.karakter == '')
      delete prove.prove.karakterer[this.elev.elevId];
    else
      prove.prove.karakterer[this.elev.elevId] = prove.karakter;

    this.prover.pushChanges(prove.prove);
  }

  getColor(num: any, den: any): string {
    if (num === '')
      return 'none';

    num = toNum(num);
    den = toNum(den);

    if (!den)
      return 'none';

    const dec = num  / den;
    const r = Math.min((1 - dec) * 512, 256);
    const g = Math.min(dec * 512, 256);

    return `rgb(${r},${g},100)`;
  }

  goBack() {
    this.location.back();
  }
}
