<button class="btn btn-primary float-right" (click)="openModal()">
  Del med elev
</button>

<div class="modal fade" id="share-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Del med elev</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Lukk"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="scores">
        <div *ngFor="let score of scores">
          <input
            class="mr-3"
            [id]="score.name"
            type="checkbox"
            [(ngModel)]="score.canView"
          />
          <label [for]="score.name">{{ score.name }}</label>
        </div>

        <div
          class="d-flex justify-content-center"
          *ngIf="loading; else notLoading"
        >
          <i class="fa fa-refresh fa-spin fa-3x"></i>
        </div>
        <ng-template #notLoading>
          <button class="btn btn-block btn-success" (click)="onSave()">
            Lagre
          </button>
          <div class="alert alert-success mt-3" *ngIf="link">
            <span>Link: </span>
            <a [href]="sub_link" target="_blank">{{ link }}</a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
