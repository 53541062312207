import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Klasse } from "../models/klasse";
import { KlasserService } from "../klasser/klasser.service";
import { Subscription } from "rxjs";
import { ProverService } from "../prover/prover.service";
import { Prove } from "../models/prove";
import { toNum } from "../utils";

@Component({
  selector: "app-year-summary",
  templateUrl: "./year-summary.component.html",
  styleUrls: ["./year-summary.component.scss"],
})
export class YearSummaryComponent implements OnInit {
  private _klasseId: string = null;
  private klasseSubscription: Subscription = null;
  public klasse: Klasse;
  public proverList: Prove[];
  public students: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private klasser: KlasserService,
    private prover: ProverService
  ) {
    route.params.subscribe((params) => {
      this.klasseId = params.klasseId;
    });
  }

  set klasseId(val: string) {
    if (val === this._klasseId) return;

    if (typeof val !== "string" || val.length === 0) {
      this._klasseId = null;
      return;
    }

    this._klasseId = val;
    this.updateKlasse();
  }

  get klasseId(): string {
    return this._klasseId;
  }

  private updateKlasse(): void {
    let klasseId = this.klasseId;

    if (!this.klasseId) {
      this.klasse = null;
      return;
    }

    if (!!this.klasseSubscription) {
      this.klasseSubscription.unsubscribe();
      this.klasseSubscription = null;
    }

    this.klasseSubscription = this.klasser
      .klasse$(this.klasseId)
      .subscribe((klasse: Klasse) => {
        // Dont update if klasse is already set and new klasse is not null
        if (!klasse || !this.klasse) {
          this.klasse = klasse;
          this.prover.setKlasse(klasse).then((_) => {
            this.proverList = this.prover.list;
          });

          if (!!klasse) {
            this.students = klasse.studentsAsArray();
          } else {
            this.students = [];
          }
        }
      });
  }

  ngOnInit(): void {}

  getKarakter(prove: Prove, elevId: number): string {
    if (!prove.hasParticipated(elevId))
      return "";
    if (!!prove.karakterer[elevId]) return prove.karakterer[elevId];

    let score = prove.getScore(elevId);

    let karakterSkala = Object.entries(this.prover.getKarakterskala(prove))
      .map((v) => ({ key: v[0], value: v[1] }))
      .sort((a: any, b: any) => b.value - a.value);

    let karakter = karakterSkala[karakterSkala.length - 1].key;

    for (let e of karakterSkala) {
      if (score.total > e.value) {
        karakter = e.key;
        break;
      }
    }

    return karakter + "";
  }

  onBack() {
    if (!!this.klasse) {
      this.klasser.updateKarakterer(this.klasse);
    }
    this.router.navigate([".."], { relativeTo: this.route });
  }

  save() {
    if (!!this.klasse) {
      this.klasser.updateKarakterer(this.klasse);
    }
  }

  getAvgHalvaar(): string {
    return ((x) =>
      x.den == 0 ? "" : Math.round((x.num * 100) / x.den) / 100 + "")(
      this.students.reduce(
        (res, student) => {
          let val = toNum(this.klasse.halvaarsvurdering[student.id]);
          if (val === 0) return res;
          res.num += val;
          res.den++;
          return res;
        },
        { num: 0, den: 0 }
      )
    );
  }

  getAvgSlutt(): string {
    return ((x) =>
      x.den == 0 ? "" : Math.round((x.num * 100) / x.den) / 100 + "")(
      this.students.reduce(
        (res, student) => {
          let val = toNum(this.klasse.sluttvurdering[student.id]);
          if (val === 0) return res;
          res.num += val;
          res.den++;
          return res;
        },
        { num: 0, den: 0 }
      )
    );
  }

  getAvgEksamen(): string {
    return ((x) =>
      x.den == 0 ? "" : Math.round((x.num * 100) / x.den) / 100 + "")(
      this.students.reduce(
        (res, student) => {
          let val = toNum(this.klasse.eksamenskarakter[student.id]);
          if (val === 0) return res;
          res.num += val;
          res.den++;
          return res;
        },
        { num: 0, den: 0 }
      )
    );
  }
}
