

<table *ngIf="data">
  <tr *ngIf="data.colHeads">
    <th *ngIf="data.rows.length && data.rows[0].head"></th>
    <th *ngFor="let h of data.colHeads">{{h.text}}</th>
    <th *ngIf="data.rows.length && data.rows[0].end"></th>
  </tr>
  <tr *ngFor='let row of data.rows;let y = index'>
    <th class="row-head" *ngIf="row.head">{{row.head}}</th>
    <td
      *ngFor='let item of row.row;let x = index'
      tabindex="0"
      (dblclick)="onDblclick(x,y)"
      (keydown)="onKeydown(x,y,$event)"
      [id]="prefix + x + '-' + y"
      class="cell"
      [ngClass]="{focus: x === editing.x && y === editing.y}"
      [style.background]="data.background ? data.background(item.obj[item.key],x,y) : ''"
    >
      <div>
        <input
          [id]="prefix + x + '-' + y + '-input'"
          *ngIf="editing.x == x && editing.y == y; else notEditing"
          (focusout)="onFocusout()"
          [(ngModel)]="item.obj[item.key]"
          (keyup)="onKeyupInput(x,y,$event)"
          [type]="
            data.colHeads && data.colHeads[x].type && data.colHeads[x].type == 'number' ?
              'number' : 'text'
          "
        />
        <ng-template #notEditing>
            {{ item.obj[item.key] }}
        </ng-template>
      </div>
    </td>
    <th class="row-end" *ngIf="row.end">{{ row.end() }}</th>
    <td *ngIf="data.pushRow && data.popRow" style="border: none; width: 70px !important;">
      <i class="fa fa-plus-circle fa-2x ml-1 text-success" style="cursor: pointer;" (click)="pushY(y)"></i>
      <i class="fa fa-minus-circle fa-2x ml-1 text-danger" style="cursor: pointer;" (click)="popY(y)"></i>
    </td>
  </tr>
  <tr></tr>
  <tr *ngIf="data.pushRow">
    <td
      [attr.colspan]="data.colHeads.length + (data.rows.length && data.rows[0].head ? 1 : 0)"
      style="text-align: center;"
    >
      <i class="fa fa-plus-circle mt-2 fa-2x text-success" style="cursor: pointer;" (click)="pushY()"></i>
    </td>
  </tr>
</table>

<!--
  [class.inFocus]="focus.inFocus && x == focus.x && y == focus.y"
  (click)="onClick(x, y)"
-->
