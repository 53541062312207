import { Injectable } from '@angular/core';
import { Instillinger } from '../models/instillinger';
import { Subscription } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InstillingerService {

  defaultKarakterer = {
    "En": 0,
    "1+": 15,
    "2-": 20,
    "To": 27,
    "2+": 35,
    "3-": 40,
    "Tre": 47,
    "3+": 55,
    "4-": 60,
    "Fire": 67,
    "4+": 75,
    "5-": 80,
    "Fem": 85,
    "5+": 90,
    "Seks": 94
  }

  instillinger = new Instillinger();
  global: any = {};

  private instillingerSubscription: Subscription = undefined;
  private instillingerDoc: AngularFirestoreDocument = undefined;

  constructor(
    private db: AngularFirestore,
    private auth: AuthService
  ) {

    db.doc('/global/settings').ref.get()
      .then(doc => {
        this.global = doc.data();
        this.global.years = this.global.years.reverse();
      });

    this.auth.user$.subscribe(usr => {

      if (this.instillingerSubscription) {
        this.instillingerSubscription.unsubscribe();
        delete this.instillingerSubscription;
      }

      if (this.instillingerDoc) {
        delete this.instillingerDoc;
      }

      if (!usr)
        return;

      this.instillingerDoc = db.doc(`/instillinger/${usr.uid}`);
      this.instillingerSubscription = this.instillingerDoc.valueChanges().subscribe(data => {
        if (!data) {
          return;
        }
        Object.assign(this.instillinger, data);
      });
    })
  }

  save(callback: (err: string) => void): void{

    if(!this.instillingerDoc) {
      callback('No internet connection, please try again later');
      return;
    }

    this.instillingerDoc.set({...this.instillinger})
      .then(() => {
        callback(null);

      }).catch((err) => {
        console.log(err);
        callback('Unknown error, please try again later');
      });

    this.getKarakterskala();
  }

  getKarakterskala(): any {
    let res =  Object.entries(this.instillinger.karakterSkala).reduce((obj, entry) => {
      if(entry[1])
        obj[entry[0]] = entry[1];
      return obj;
    }, {...this.defaultKarakterer});
    return res;
  }
}
