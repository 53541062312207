import { Component, OnInit } from '@angular/core';
import { InstillingerService } from '../instillinger/instillinger.service';

@Component({
  selector: 'app-aar',
  templateUrl: './aar.component.html',
  styleUrls: ['./aar.component.scss']
})
export class AarComponent implements OnInit {

  constructor(
    public instillinger: InstillingerService
  ) { }

  ngOnInit() {
  }

}
