import { Component, OnDestroy, ViewChildren, QueryList, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Klasse } from 'src/app/models/klasse';
import { KlasserService } from 'src/app/klasser/klasser.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProverService } from '../prover.service';
import { Prove } from 'src/app/models/prove';
import { SpreadSheetData, SpreadSheetComponent } from 'src/app/misc/spread-sheet/spread-sheet.component';
import { toNum } from '../../utils';
import { skipUntil, max } from 'rxjs/operators';
import { parse } from 'path';
declare function $(params: any): any;


@Component({
  selector: 'app-prove-single',
  templateUrl: './prove-single.component.html',
  styleUrls: ['./prove-single.component.scss']
})
export class ProveSingleComponent implements OnDestroy {

  editName = false;

  klasse: Klasse;
  prove: Prove;
  karakterer: {[id: number]: string} = {};
  students: any[] = null;

  editingI = -1;
  editingJ = -1;

  focusI = -1;
  focusJ = -1;

  private klasseSubscription: Subscription;
  private proveSubscription: Subscription;

  @ViewChildren(SpreadSheetComponent) retting: QueryList<SpreadSheetComponent>;

  constructor(
    private klasser: KlasserService,
    private prover: ProverService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    route.params.subscribe(params => {

      this.clear();

      this.klasseSubscription = klasser.klasse$(params.klasseId)
        .subscribe(klasse => {

          if (!klasse || !this.klasse) {
            this.klasse = klasse;

            if (!!klasse) {
              this.students = klasse.studentsAsArray();
            }

            this.prover.setKlasse(klasse);

            if (this.proveSubscription) {
              this.proveSubscription.unsubscribe();
              this.proveSubscription = undefined;
            }

            if (this.prove) {
              this.prove = undefined;
            }

            this.proveSubscription = this.prover.prove$(params.proveId)
              .subscribe(prove => {


                if (!prove || !this.prove) {
                  this.prove = prove;
                }

                if(this.retting)
                  this.retting.forEach(r => {
                    r.updateData();
                  });

              });
          }
        });
    });
  }

  ngOnDestroy() {
    this.prover.pushChanges(this.prove);
    this.clear();
  }

  edit(i, j): void {
    this.editingI = i;
    this.editingJ = j;

    if (i == -1 || j == -1)
      return;

    setTimeout(() => {
      let input: HTMLElement = document.querySelector(`#si-${i}-${j} input`);

      if (!input) {
        console.error('faield to set focus to input');
        return;
      }

      input.focus();
    }, 0);
  }

  isEditing(): boolean {
    return this.editingI != -1 && this.editingJ != -1;
  }

  onFocusout(del, oppgaveId, studentId) {

    this.focusI = -1;
    this.focusJ = -1;

    this.edit(-1, -1);

    let max_score = (
      del == 'del1' ? this.prove.oppgaverDel1 : this.prove.oppgaverDel2
    ).filter((oppgave) => oppgaveId == oppgave.id)[0].maxPoints;

    if (typeof this.prove.scores[studentId][del][oppgaveId] != 'number') {
      this.prove.scores[studentId][del][oppgaveId] = '';
    } if (this.prove.scores[studentId][del][oppgaveId] > max_score) {
      this.prove.scores[studentId][del][oppgaveId] = max_score;
    }
  }

  onKeydown(i, j, student, oppgave, del, e) {

    if (this.isEditing()) {
      if (e.key == 'Enter') {
        let next = document.getElementById(`si-${i + 1}-${j}`);
        if (next) {
          next.focus();
        }
      }
      return;
    }

    if (!(parseInt(e.key) !== parseInt(e.key)))  {
      if (this.getLaas(student.id))
        return;

      setTimeout(() => {
        this.prove.scores[student.id][del][oppgave.id] = parseInt(e.key);
        this.edit(i, j);
      }, 0);
      return;
    }

    let next: HTMLElement = null;

    switch(e.key) {
      case 'ArrowRight':
        next = document.getElementById(`si-${i}-${j + 1}`);
        break;
      case 'ArrowLeft':
        next = document.getElementById(`si-${i}-${j - 1}`);
        break;
      case 'ArrowUp':
        next = document.getElementById(`si-${i - 1}-${j}`);
        break;
      case 'ArrowDown':
      case 'Enter':
        next = document.getElementById(`si-${i + 1}-${j}`);
        break;
    }

    if (next)
      next.focus();
  }

  onDbclick(i, j, student) {
    if (this.getLaas(student.id))
      return;
    this.edit(i, j);
  }

  clear(): void {
    if (this.klasseSubscription) {
      this.klasseSubscription.unsubscribe();
      this.klasseSubscription = undefined;
    }

    if (this.klasse) {
      this.klasse = undefined;
    }

    if (this.proveSubscription) {
      this.proveSubscription.unsubscribe();
      this.proveSubscription = undefined;
    }

    if (this.prove) {
      this.prove = undefined;
    }

    this.prover.setKlasse(null);
  }


  onDelete(): void {
    $('#delete-prove-modal').modal('show');
  }

  onDeleteConfirm(): void {
    $('#delete-prove-modal').modal('hide');
    this.prover.deleteProve(this.klasse, this.prove);
    this.router.navigate(['../..'], {relativeTo: this.route});
  }


  // Enable edit mode for the prove name
  onEditName() {
    this.editName = true;
    setTimeout(() => {
      $('#edit-name').focus();
    }, 0);
  }

  save() {
    this.prover.pushChanges(this.prove);
  }

  // Save the new prove name
  onSaveName() {
    this.editName = false;
    this.prover.pushChanges(this.prove);
  }

  onSaveSettings() {

    if (this.prove) {
      this.prove.initScores();
    }

    if (this.retting)
      this.retting.forEach(r => {
        r.updateData();
      });

    this.prover.pushChanges(this.prove);
  }

  getColor(maxPoints, points): string {
    if (!maxPoints)
      return 'none';

    if (typeof points != 'number') {
      return `rgb(255,255,255)`;
    }

    const dec = parseFloat(points + '')  / maxPoints;
    const r = Math.min((1 - dec) * 512, 256);
    const g = Math.min(dec * 512, 256);

    return `rgb(${r},${g},100)`;
  }

  private getSpreadsheetData(data: SpreadSheetData, oppgaver: any, del: string): SpreadSheetData {

    if (!this.prove) {
      data = null;
      return data;
    }

    if (!data) {
      data = new SpreadSheetData();
      data.background = (val: number|string, x: number, y: number) => {

        if (val === '') {
          return `none`;
        }

        const dec = parseFloat(val + '')  / oppgaver[x].maxPoints;
        const r = Math.min((1 - dec) * 512, 256);
        const g = Math.min(dec * 512, 256);

        return `rgb(${r},${g},100)`;
      };
    }

    data.colHeads = oppgaver
      .map(oppgave => ({
	      text: `${oppgave.name} (${oppgave.maxPoints})`,
	      type: 'number',
        max: oppgave.maxPoints - 0
      }));

    data.rows = Object.keys(this.prove.scores)
      .map(elevId => ({
        row: oppgaver
          .map(oppgave => ({
            obj: this.prove.scores[elevId][del],
            key: oppgave.id + ''
          })),
        head: this.prove.klasse.students[elevId],
        end: () => Math.round(((x) => x.sum / x.max)(oppgaver.reduce((obj, oppgave) => {
          if (typeof this.prove.scores[elevId][del][oppgave.id] === 'number')
            obj.sum += this.prove.scores[elevId][del][oppgave.id];
          obj.max += oppgave.maxPoints;
          return  obj;
        }, {sum: 0, max: 0})) * 10000) / 100 + '%'
      }));

    return data;
  }

  getResultDel1(elevId) {

    let sum = 0;
    let sum_max = 0;

    for (let oppgave of this.prove.oppgaverDel1) {
      sum_max += toNum(oppgave.maxPoints);
      sum += toNum(this.prove.scores[elevId].del1[oppgave.id]);
    }

    if (sum_max === 0) return 0;

    return Math.round(sum * 10000 / sum_max) / 100;
  }

  getResultDel2(elevId) {

    let sum = 0;
    let sum_max = 0;

    for (let oppgave of this.prove.oppgaverDel2) {
      sum_max += toNum(oppgave.maxPoints);
      sum += toNum(this.prove.scores[elevId].del2[oppgave.id]);
    }

    if (sum_max === 0) return 0;

    return Math.round(sum * 10000 / sum_max) / 100;
  }

  getResult(elevId) {

    let sum = 0;
    let sum_max = 0;

    for (let oppgave of this.prove.oppgaverDel1) {
      sum_max += toNum(oppgave.maxPoints);
      sum += toNum(this.prove.scores[elevId].del1[oppgave.id]);
    }

    for (let oppgave of this.prove.oppgaverDel2) {
      sum_max += toNum(oppgave.maxPoints);
      sum += toNum(this.prove.scores[elevId].del2[oppgave.id]);
    }

    if (sum_max === 0) return 0;

    return Math.round(sum * 10000 / sum_max) / 100;
  }

  getSpreadsheetDataFunction(oppgaver: any, del): (data: SpreadSheetData) => SpreadSheetData {
    return (data: SpreadSheetData) => this.getSpreadsheetData(data, oppgaver, del);
  }

  getStudents(): any {

    if (!this.klasse)
      return [];

    if (!this.prove)
      return [];

    let sumDel1 =
      Object.values(this.prove.oppgaverDel1)
        .reduce((total, s) => total + s.maxPoints, 0);

    let sumDel2 =
      Object.values(this.prove.oppgaverDel2)
        .reduce((total, s) => total + s.maxPoints, 0);

    let res = Object.entries(this.klasse.students).map((e) => {
      return {
        id: e[0],
        ...this.prove.getScore(Number(e[0])),
        name: e[1]
      };
    });

    return res;
  }

  setKarakter(elevId: number, e: any): void {
    let val = e.target.value;
    if (typeof val != 'string' || val == '')
      delete this.prove.karakterer[elevId];
    else
      this.prove.karakterer[elevId] = val;
  }

  getSugestedKarakter(elevId): string {
    if (!this.prove.hasParticipated(elevId))
      return "";
    let score = this.prove.getScore(elevId);
    let karakterSkala = Object.entries(this.prover.getKarakterskala(this.prove))
      .map(v => ({key: v[0], value: v[1]}))
      .sort((a: any, b: any) => b.value - a.value);
    let karakter = karakterSkala[karakterSkala.length - 1].key;

    for (let e of karakterSkala) {
      if (score.total > e.value){
        karakter = e.key;
        break;
      }
    }

    return karakter + "";
  }

  delMedElev(elevId): void {
    this.prover.delMedElev(this.prove, elevId);
  }

  getLaas(studentId: number): boolean {
    studentId = toNum(studentId);
    return !!this.prove.studentLock[studentId];
  }

  onLaas(studentId: number) {
    studentId = toNum(studentId);
    if (this.prove.studentLock[studentId]) {
      delete this.prove.studentLock[studentId];
    } else {
      this.prove.studentLock[studentId] = true;
    }
  }

  getAvgDel1(oppgave) {
    let sum = 0;
    let count = 0;
    for (let elev of this.klasse.studentsAsArray()) {
      let val = this.prove.scores[elev.id].del1[oppgave.id];
      if (typeof val !== 'number')
        continue;
      val = toNum(val);
      sum += val;
      count++;
    }
    return count ? Math.round(sum * 100 / count) / 100 : "";
  }

  getAvgDel2(oppgave) {
    let sum = 0;
    let count = 0;
    for (let elev of this.klasse.studentsAsArray()) {
      let val = this.prove.scores[elev.id].del2[oppgave.id];
      if (typeof val !== 'number')
        continue;
      val = toNum(val);
      sum += val;
      count++;
    }
    return count ? Math.round(sum * 100 / count) / 100 : "";
  }

  getAvgResultDel1() {
    let sum = 0;
    let count = 0;
    for (let elev of this.klasse.studentsAsArray()) {
      sum += toNum(this.getResultDel1(elev.id));
      count++;
    }
    return count ? Math.round(sum * 100 / count) / 100 : 0;
  }

  getAvgResultDel2() {
    let sum = 0;
    let count = 0;
    for (let elev of this.klasse.studentsAsArray()) {
      sum += toNum(this.getResultDel2(elev.id));
      count++;
    }
    return count ? Math.round(sum * 100 / count) / 100 : 0;
  }

  getAvgResult() {
    let sum = 0;
    let count = 0;
    for (let elev of this.klasse.studentsAsArray()) {
      sum += toNum(this.getResult(elev.id));
      count++;
    }
    return count ? Math.round(sum * 100 / count) / 100 : 0;
  }

  onFocusin(i, j) {
    this.focusI = i;
    this.focusJ = j;
  }
}

