<div class="container">

<h1 class="mt-4">Instillinger</h1>

<button class="btn btn-success mt-3" (click)="onSave()">Lagre</button>

<h2 class="mt-3">Standard karakterskala</h2>

<div class="row">
    <div class="col-md-6 col-12">
        <div class="input-group" *ngFor="let karakter of karaktererRow1">
            <div class="input-group-prepend">
                <span class="input-group-text karakter-span">
                    {{karakter}}:
                </span>
            </div>
            <input class="form-control" type="number" 
                [placeholder]="instillinger.defaultKarakterer[karakter]"
                [(ngModel)]="instillinger.instillinger.karakterSkala[karakter]">
            <div class="input-group-append">
                <span class="input-group-text">
                    %
                </span>
            </div>
        </div>
    </div>

    <div class="col-md-6 col-12">
        <div class="input-group" *ngFor="let karakter of karaktererRow2">
            <div class="input-group-prepend">
                <span class="input-group-text karakter-span">
                    {{karakter}}:
                </span>
            </div>
            <input class="form-control" type="number" 
                [placeholder]="instillinger.defaultKarakterer[karakter]"
                [(ngModel)]="instillinger.instillinger.karakterSkala[karakter]">
            <div class="input-group-append">
                <span class="input-group-text">
                    %
                </span>
            </div>
        </div>
    </div>
</div>

<button class="btn btn-success mt-4" (click)="onSave()">Lagre</button>

</div>
