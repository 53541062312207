import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { InstillingerService } from './instillinger.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-instillinger',
  templateUrl: './instillinger.component.html',
  styleUrls: ['./instillinger.component.scss']
})
export class InstillingerComponent implements OnInit {

  karaktererRow1 = [
    "Seks",
    "5+",
    "Fem",
    "5-",
    "4+",
    "Fire",
    "4-",
    "3+"
  ];

  karaktererRow2 = [
    "Tre",
    "3-",
    "2+",
    "To",
    "2-",
    "1+",
    "En"
  ];

  constructor(
    public instillinger: InstillingerService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
  }

  onSave() {
    this.instillinger.save((err) => {
      if (err) {
        alert(err);
        return;
      }
      console.log('saved instillinger successfully');
      this.location.back();
    });
  }
}
