
<div class="container">
  <button class="btn btn-primary mt-3" routerLink="../..">Tilbake</button>

  <div class="row mt-3">
    <div class="col-lg-4 col-sm-3"></div>
    <div class="col-lg-4 col-sm-6  col-12">
      <h1>Klasser</h1>

      <button 
        *ngIf="aar == instillinger.global.thisYear" 
        class="btn btn-success btn-block mb-2" 
        (click)="onOpenNewKlasse()"
      >Ny klasse</button>

      <!-- LOADING SPINNER -->
      <div *ngIf="klasser.loading" class="ra-spinner mb-2">
        <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
      </div>

      <!-- KLASSER -->
      <div class="ra-klasser">
        <div class="ra-shaddow-box" *ngFor="let klasse of getKlasser()" [routerLink]="[klasse.id]">
          {{klasse.name}}
        </div>
      </div>

    </div>
  </div>

  <!-- ADD KLASSE MODAL -->
  <div class="modal fade" id="add-klasse-modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Legg til ny klasse</h4>
        </div>
        <div class="modal-body">
          <input type="text" placeholder="Klassens navn" class="form-control" [(ngModel)]="newName" >
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="onNewKlasse()" [disabled]="newName == ''">Legg til klasse</button>
        </div>
      </div>
    </div>
  </div>
</div>