import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LoginComponent } from './auth/login/login.component';
import { KlasserComponent } from './klasser/klasser/klasser.component';
import { KlasserSingleComponent } from './klasser/klasser-single/klasser-single.component';
import { ProveSingleComponent } from './prover/prove-single/prove-single.component';
import { ProveSettingsComponent } from './prover/prove-single/prove-settings/prove-settings.component';
import { SpreadSheetComponent } from './misc/spread-sheet/spread-sheet.component';
import { InstillingerComponent } from './instillinger/instillinger.component';
import { ElevSingleComponent } from './elev/elev-single/elev-single.component';
import { AarComponent } from './aar/aar.component';
import { ElevShareComponent } from './elev/elev-single/elev-share/elev-share.component';
import { ElevViewComponent } from './elev-view/elev-view.component';
import { EgenvurderingComponent } from './elev-view/egenvurdering/egenvurdering.component';
import { YearSummaryComponent } from './year-summary/year-summary.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { RegistrationCompleteComponent } from './auth/registration/registration-complete/registration-complete.component';
import { CustomErrorHandler } from './custom-error-handler';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    KlasserComponent,
    KlasserSingleComponent,
    ProveSingleComponent,
    ProveSettingsComponent,
    SpreadSheetComponent,
    InstillingerComponent,
    ElevSingleComponent,
    AarComponent,
    ElevShareComponent,
    ElevViewComponent,
    EgenvurderingComponent,
    YearSummaryComponent,
    RegistrationComponent,
    RegistrationCompleteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
