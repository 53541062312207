<div class="container">
  <h1 class="mt-5">Egenvurdering: {{ name }}</h1>

  <div class="mt-3 row" *ngIf="egenretting">
    <div class="col-12">
      <h2>Egenretting:</h2>
    </div>
    <div class="col-md-6" *ngIf="!!del1.length">
      <table>
        <tr>
          <th scope="col" colspan="3">Del 1</th>
        </tr>
        <tr>
          <th scope="col">Oppgave</th>
          <th scope="col">Max Poeng</th>
          <th scope="col">Poengsum</th>
        </tr>
        <tr *ngFor="let oppgave of del1">
          <th scope="row">{{oppgave.name}}</th>
          <td>{{oppgave.max}}</td>
          <td><input (focusout)="validate(oppgave)" type="number" step="0.01"[(ngModel)]="oppgave.svar"></td>
        </tr>
      </table>
    </div>
    <div class="col-md-6" *ngIf="!!del2.length">
      <table>
        <tr>
          <th scope="col" colspan="3">Del 2</th>
        </tr>
        <tr>
          <th scope="col">Oppgave</th>
          <th scope="col">Maks Poeng</th>
          <th scope="col">Poengsum</th>
        </tr>
        <tr *ngFor="let oppgave of del2">
          <th scope="row">{{oppgave.name}}</th>
          <td>{{oppgave.max}}</td>
          <td><input (focusout)="validate(oppgave)" type="number" step="0.01"[(ngModel)]="oppgave.svar"></td>
        </tr>
      </table>
    </div>
  </div>

  <div class="mt-3">
    <div *ngFor="let s of sporsmaal" class="mt-3">
      <h3>{{ s }}</h3>
      <textarea [(ngModel)]="svar[s]"></textarea>
    </div>
    <div *ngIf="error" class="alert alert-danger mt-3">{{ error }}</div>
    <button class="btn btn-lg btn-primary mt-3 mb-3" (click)="send()">
      Send inn
    </button>
  </div>
</div>
