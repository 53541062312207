import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { Prove } from "src/app/models/prove";

@Component({
  selector: "app-egenvurdering",
  templateUrl: "./egenvurdering.component.html",
  styleUrls: ["./egenvurdering.component.scss"],
})
export class EgenvurderingComponent implements OnInit {
  name = "";
  sporsmaal = [];
  del1 = [];
  del2 = [];
  svar = {};
  egenretting = false;
  error: string = null;
  elev: string = null;

  constructor(
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.elev = params.id;

      this.db
        .doc(`elev/${params.id}`)
        .get()
        .subscribe((elev) => {
          let prove = elev
            .data()
            .prover.filter((prove) => prove.name == params.prove)[0];
          let vurdering = prove.egenvurdering;

          for (let s of vurdering) {
            this.svar[s] = "";
          }

          try {
            this.del1 = prove.score.del1Oppgaver.map((e) => ({
              name: e.name,
              max: e.max,
              svar: undefined,
            }));
          } catch (e) {
            console.error(e);
          }

          try {
            this.del2 = prove.score.del2Oppgaver.map((e) => ({
              name: e.name,
              max: e.max,
              svar: undefined,
            }));
          } catch (e) {
            console.error(e);
          }

          this.egenretting = prove.egenretting;
          this.sporsmaal = vurdering;
          this.name = params.prove;
        });
    });
  }

  send() {

    try {

    console.log('send function triggered');

    console.log('Sjekker egenretting');
    if (this.egenretting) {
      for (let oppgave of [...this.del1, ...this.del2]) {
        if (!oppgave.svar && oppgave.svar !== 0) {
          this.error = "Du må svare på alle spørsmålene";
          console.log("Et av svarene i egenretting er ikke besvart");
          return;
        }
      }
    }

    console.log('Sjekker egenvurdering svar');
    for (let s of this.sporsmaal) {
      if (this.svar[s] == "") {
        this.error = "Du må svare på alle spørsmålene";
        console.log('Et av egenvurderingsporsmaalene er ikke besvart');
        return;
      }
    }

    this.error = "";
    console.log('Laster opp egenvurdering');

    this.db
      .doc(`elev/${this.elev}/egenvurdering/${this.name}`)
      .set(this.svar)
      .then((res) => {
        console.log('Lastet opp egenvurdering');
        if (this.egenretting) {
          console.log('Laster opp egenrettting')
          return this.db.doc(`elev/${this.elev}/egenretting/${this.name}`).set({
            del1: this.del1.map((e) => e.svar),
            del2: this.del2.map((e) => e.svar),
          });
        }
        console.log('Ingen elevretting');
        return;
      })
      .then(() => {
        console.log('Navigerer tilbake til elevsiden')
        this.router.navigate(["elev", this.elev]);
      })
      .catch((err) => {
        console.log('En ukjent feil oppstod');
        this.error = "Ukjent feil, kontakt support";
        console.log(err);
      });
    } catch (e) {
      console.log('En feil oppstod')
      console.log(e);
      console.log(e.message);
    }
  }

  validate(oppgave: any): void {
    if (!oppgave.svar && oppgave.svar !== 0) {
      oppgave.svar = undefined;
      return;
    }
    oppgave.svar = Number(oppgave.svar);
    if (oppgave.svar != oppgave.svar || oppgave.svar < 0) {
      oppgave.svar = 0;
    }
    if (oppgave.svar > oppgave.max) {
      oppgave.svar = oppgave.max;
    }
  }
}
