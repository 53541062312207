import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { auth, firestore } from 'firebase';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { pathToFileURL } from 'url';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss']
})
export class RegistrationCompleteComponent implements OnInit {

  user: User;
  loading = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private firestore: AngularFirestore
  ) {
    auth.userDoc.pipe(first()).subscribe(userDoc => {
      if (userDoc)
        router.navigate(['/']);
    });

    auth.user$.subscribe(user => {
      if (!user)
        router.navigate(['/']);
      else
        this.user = new User(auth.uid);
    });
  }

  ngOnInit(): void {
  }

  isReady(): boolean {
    if (!this.user.fullName)
      return false;
    return true;
  }

  async submit() {
    if (!this.isReady())
      return;
    this.loading = true;
    await this.firestore.doc(`/users/${this.user.id}`).set(this.user.toObject());
    this.auth.userDoc.next(this.user);
    this.router.navigate(['/']);
  }
}

