import {ErrorHandler, Injectable} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth/auth.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  constructor(
    private auth: AuthService,
    private db: AngularFirestore
  ) {

  }

  handleError(error) {
    console.log('Cought error: Attempting to send error to DB');
    try {
      const uid = this.auth.uid || 'no_user';
      const d = new Date().toISOString();
      this.db.collection(`/error/${uid}/timestamp`).doc(d).set({
        message: error.message,
        stack: error.stack
      }).then(res => {
        console.log(`Error uploaded to db: /error/${uid}/timestamp/${d}`);
      }).catch(err => {
        throw err;
      });
    } catch (e) {
      console.error('Failed to send error to db:', e.message);
    }
  }
}