<div class="container" *ngIf="user">

  <div class="row">
    <div class="col-lg-4 col-sm-3"></div>
    <div class="col-lg-4 col-sm-6  col-12">
      <h1 class="mt-5">Fullfør registrering</h1>

      <!-- Fult navn -->
      <div class="input-group mb-3 mt-5">
        <input type="text" class="form-control" placeholder="Fult Navn *" [(ngModel)]="user.fullName">
      </div>

      <!-- Complete BUTTON -->
      <button class="btn btn-primary btn-block" [disabled]="!isReady()"
        *ngIf="!loading" (click)="submit()">Ferdig</button>

      <!-- LOADING SPINNER -->
      <div *ngIf="loading" class="ra-spinner mb-2">
        <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
      </div>
    </div>

  </div>