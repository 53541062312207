<div class="container">
  <!-- LOADING SPINNER -->
  <div class="row mt-3">
    <div class="col-12">
      <button class="btn btn-primary" routerLink="..">Tilbake</button>
      <button
        class="btn btn-danger float-right"
        *ngIf="klasse"
        (click)="onDelete()"
      >
        Slett klassen
      </button>

      <div *ngIf="klasse === undefined" class="ra-spinner mt-3">
        <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
      </div>

      <div *ngIf="klasse === null" class="alert alert-danger mt-3" role="alert">
        Du har ikke tilgang til denne klassen
      </div>
    </div>
  </div>

  <div class="row" *ngIf="klasse">
    <!-- KLASSE NAME-->
    <div class="col-12 klasse-title">
      <ng-template #titleView>
        <h1 style="text-align: center;">{{ klasse.name }}</h1>

        <i
          class="fa fa-edit fa-2x text-secondary ml-3"
          (click)="onEditName()"
        ></i>
      </ng-template>

      <div class="input-group input-group-lg" *ngIf="editName; else titleView">
        <input
          type="text"
          id="edit-name"
          class="form-control"
          [(ngModel)]="klasse.name"
          (focusout)="onSaveName()"
          (keyup.enter)="onSaveName()"
        />
      </div>
    </div>

    <div class="col-md-1"></div>

    <!-- PROVER -->
    <div class="col-md-4 mb-4">
      <h3 style="text-align: center; text-decoration: underline;" class="mb-3">
        Prøver
      </h3>

      <div *ngIf="prover.loading; else proveListing" class="ra-spinner mt-3">
        <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
      </div>

      <ng-template #proveListing>
        <button class="btn btn-success btn-block" (click)="onNewProve()">
          Ny prøve
        </button>

        <div
          class="ra-shaddow-box"
          *ngFor="let prove of prover.list"
          [routerLink]="['prove', prove.id]"
        >
          {{ prove.name }}
        </div>
      </ng-template>

      <button
        routerLink="./oppsummering"
        class="btn btn-primary btn-block mt-3"
      >
        Årsoppsummering
      </button>
      <button
        class="btn btn-success btn-block mt-3"
        (click)="onElevdelingOpen()"
      >
        Elevdeling
      </button>
    </div>

    <div class="col-md-2"></div>

    <!-- STUDENTS -->
    <div class="col-md-4 mb-4">
      <h3 style="text-align: center; text-decoration: underline;" class="mb-3">
        Elever
      </h3>

      <button class="btn btn-success btn-block" (click)="onNewStudent()">
        Ny elev
      </button>

      <div
        class="ra-shaddow-box"
        *ngFor="let student of klasse.studentsAsArray()"
        [routerLink]="['elev', student.id]"
      >
        {{ student.name }}
        <span
          class="float-right text-danger delete-student"
          (click)="onDeleteStudent(student.id)"
          >SLETT</span
        >
      </div>
    </div>
  </div>

  <!-- ADD STUDENT MODAL -->
  <div class="modal fade" id="add-student-modal" *ngIf="klasse">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Legg til elev</h4>
        </div>
        <div class="modal-body">
          <div
            *ngIf="klasse.hasStudent(newStudentName)"
            class="alert alert-danger"
            role="alert"
          >
            Alle elever må ha unike navn
          </div>
          <textarea
            type="text"
            placeholder="Elevens navn"
            class="form-control mb-3"
            [(ngModel)]="newStudentName"
          ></textarea>
          <div class="mb-3 alert alert-warning">
            Du kan legge til flere elever ved skrive dem på hver sin linje
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success"
            (click)="onNewStudentConfirm()"
            [disabled]="
              newStudentName == '' || klasse.hasStudent(newStudentName)
            "
          >
            Legg til elev
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- ELEVDELING MODAL -->
  <div class="modal fade" id="student-share-modal" *ngIf="klasse">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Elevdeling</h4>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="elevdelingOversikt; else elevdelingLoading">
            <h4 class="mb-2">Del med alle elever:</h4>
            <div class="row mb-4">
              <div class="col-lg-8 mb-3">
                <div class="btn-group btn-block">
                  <button
                    type="button"
                    class="btn btn-outline-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ !proveTilDeling ? "Prøve" : proveTilDeling.name }}
                  </button>
                  <div
                    class="dropdown-menu text-center"
                    style="min-width: 100%;"
                  >
                    <a
                      class="dropdown-item"
                      *ngFor="let prove of prover.list"
                      (click)="selectProveForDeling(prove)"
                      >{{ prove.name }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <button
                  class="btn btn-success btn-block"
                  [disabled]="!proveTilDeling"
                  (click)="delMedAlle()"
                >
                  Del med alle
                </button>
              </div>
            </div>
            <h4 class="mb-3">Oversikt:</h4>
            <table class="elevdeling-table">
              <tr>
                <th scope="col">Elev</th>
                <th scope="col">Delte prøver</th>
                <th scope="col">Lenke</th>
              </tr>
              <tr *ngFor="let elev of elevdelingOversikt">
                <td>{{ elev.elev }}</td>
                <td>{{ elev.prover }}</td>
                <td>
                  <a target="_blank" [href]="elev.link_rel">
                    {{ elev.link }}
                  </a>
                </td>
              </tr>
            </table>
            <div
              *ngIf="elevdelingOversikt.length === 0; else notEmpty"
              class="alert alert-warning mt-3"
            >
              Du har ikke delt noen prøver med noen elever
            </div>
            <ng-template #notEmpty>
              <div class="alert alert-warning mt-3">
                <strong>Tips:</strong> Du kan kopiere tabellen og lime den inn i
                et excel ark. Da er den lett å skrive ut
              </div>
            </ng-template>
          </ng-container>
          <ng-template #elevdelingLoading>
            <div class="d-flex justify-content-center">
              <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- DELETE KLASSE MODAL -->
  <div class="modal fade" id="delete-student-modal" *ngIf="klasse">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-danger text-light">
          <h4>Slett elev</h4>
        </div>
        <div class="modal-body">
          Er du sikker på at du vil slette eleven
          <span class="text-danger">{{ klasse.students[deleteStudentId] }}</span
          >?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            (click)="onDeleteStudentConfirm()"
          >
            Slett {{ klasse.students[deleteStudentId] }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- ADD PROVE MODAL -->
  <div class="modal fade" id="add-prove-modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Legg til prøve</h4>
        </div>
        <div class="modal-body">
          <input
            type="text"
            placeholder="Prøvens navn"
            class="form-control mb-3"
            [(ngModel)]="newProveName"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success"
            (click)="onNewProveConfirm()"
            [disabled]="newProveName == ''"
          >
            Legg til prøve
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- DELETE KLASSE MODAL -->
  <div class="modal fade" id="delete-klasse-modal" *ngIf="klasse">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-danger text-light">
          <h4>Slett klasse</h4>
        </div>
        <div class="modal-body">
          Er du sikker på at du vil slette klassen
          <span class="text-danger">{{ klasse.name }}</span
          >?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            (click)="onDeleteConfirm()"
          >
            Slett {{ klasse.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
