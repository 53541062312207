
export class Klasse {

  id: string;
  year: string;
  owner: string;
  name: string;
  nextStudentId = 0;
  students: {[i: number]: string} = {};
  halvaarsvurdering: {[studentId: number]: number} = {};
  sluttvurdering: {[studentId: number]: number} = {};
  eksamenskarakter: {[studentId: number]: number} = {};

  constructor(data: any) {
    Object.assign(this, data);
  }

  update(data: any) {
    Object.assign(this, data);
  }

  addStudent(name: string) {
    let names = name.split('\n');
    names = names.map(name => name.trim());
    names = names.filter(name => !!name);
    for (let name of names)
      this.students[this.nextStudentId++] = name;
  }

  deleteStudent(id: number) {
    delete this.students[id];
    console.log(this.students);
  }

  hasStudent(name: string) {
    let names = name.split('\n');
    names = names.map(name => name.trim());
    names = names.filter(name => !!name);
    for (let name of names) {
      if (Object.values(this.students).includes(name))
        return true;
    }
    return false;
  }

  studentsAsArray() {
    return Object.keys(this.students)
      .filter(key => key !== 'next')
      .map(key => ({id: Number(key), name: String(this.students[key])}));
  }

  getStudentName(elevId: number): string {
    try {
      return this.students[elevId];
    } catch {
      return null;
    }
  }
}
