import { Klasse } from './klasse';

export class Prove {

  id: string;
  klasse: Klasse;

  name: string;

  karakterer: {
    [elev: number]: string
  } = {};

  karakterskala: {
    [navn: string]: number
  } = {};

  kommentar: {
    [elev: number]: string
  } = {};

  egenvurdering: {text: string}[] = [];
  egenretting = true;
  openToElev: number[] = [];
  studentLock: {[studentId: number]: boolean} = {};

  nextOppgaveIdDel1 = 0;
  oppgaverDel1: {
    id: number,
    name: string,
    maxPoints: number
  }[] = [];

  nextOppgaveIdDel2 = 0;
  oppgaverDel2: {
    id: number,
    name: string,
    maxPoints: number
  }[] = [];

  scores: {
    [elev: number]: {
      del1: {[oppgave: number]: number | string},
      del2: {[oppgave: number]: number | string}
    }
  } = {};

  constructor(data: any) {
    Object.assign(this, data);
    this.initScores();
  }

  update(data: any) {
    Object.assign(this, data);
    this.initScores();
  }

  hasParticipated(elevId: number) {
    for (let oppagave of this.oppgaverDel1) {
      if (this.scores[elevId].del1[oppagave.id] !== "")
        return true;
    }
    for (let oppagave of this.oppgaverDel2) {
      if (this.scores[elevId].del2[oppagave.id] !== "")
        return true;
    }
    return false;
  }

  getScore(elevId: number) {

    let del1 = this.oppgaverDel1.reduce((score, oppgave) => {
      score.max += oppgave.maxPoints;
      score.elev -= -this.scores[elevId].del1[oppgave.id];
      return score;
    }, {elev: 0, max: 0});

    let del1Oppgaver: any[] = [];
    del1Oppgaver = this.oppgaverDel1.map(oppgave => {
      return {
        name: oppgave.name,
        max: oppgave.maxPoints,
        value: this.scores[elevId].del1[oppgave.id] + ""
      }
    });

    let del2 = this.oppgaverDel2.reduce((score, oppgave) => {
      score.max += oppgave.maxPoints;
      score.elev -= -this.scores[elevId].del2[oppgave.id];
      return score;
    }, {elev: 0, max: 0});

    let del2Oppgaver: any[] = [];
    del2Oppgaver = this.oppgaverDel2.map(oppgave => {
      return {
        name: oppgave.name,
        max: oppgave.maxPoints,
        value: this.scores[elevId].del2[oppgave.id] + ""
      }
    });

    let result: any = {
      name: this.name,
      del1: Math.round(10000 * del1.elev / del1.max) / 100,
      del1Oppgaver,
      del2: Math.round(10000 * del2.elev / del2.max) / 100,
      del2Oppgaver,
      total: Math.round(10000 * (del1.elev + del2.elev) / (del1.max + del2.max)) / 100,
      kommentar: this.kommentar[elevId]
    };

    if (this.karakterer[elevId] != null) {
      result.karakter = this.karakterer[elevId];
    }

    return result;
  }

  addOppgaveDel1(name: string, maxPoints: number, i?: number) {

    if (i === undefined)
      this.oppgaverDel1.push({ id: this.nextOppgaveIdDel1++, name, maxPoints });
    else
      this.oppgaverDel1.splice(i, 0, { id: this.nextOppgaveIdDel1++, name, maxPoints });

    this.initScores();
  }

  addOppgaveDel2(name: string, maxPoints: number, i?: number) {

    if (i === undefined)
      this.oppgaverDel2.push({ id: this.nextOppgaveIdDel2++, name, maxPoints });
    else
      this.oppgaverDel2.splice(i, 0, { id: this.nextOppgaveIdDel2++, name, maxPoints });

    this.initScores();
  }

  removeOppgaveDel1(i: number) {
    this.oppgaverDel1.splice(i, 1);
    this.initScores();
  }


  removeOppgaveDel2(i: number) {
    this.oppgaverDel2.splice(i, 1);
    this.initScores();
  }

  updateKarakter(elevId: number, karakter: string) {
    this.karakterer[elevId] = karakter;
  }

  initScores() {

    const oppgaveDel1IdArray = this.oppgaverDel1.map(oppgave => oppgave.id);
    const oppgaveDel2IdArray = this.oppgaverDel2.map(oppgave => oppgave.id);

    // Add elevId to scores if elevId is not in scores
    for (const elevId of Object.keys(this.klasse.students)) {

      if (!Object.keys(this.scores).includes(elevId)) {
        this.scores[elevId] = {
          del1: {},
          del2: {}
        };
      }
    }

    for (const oppgave of this.oppgaverDel1) {
	    oppgave.maxPoints -= 0;
    }

    for (const oppgave of this.oppgaverDel2) {
	    oppgave.maxPoints -= 0;
    }

    for (const elevId of Object.keys(this.scores)) {


      // Delete elev entries that does not exists in klasse
      if (!Object.keys(this.klasse.students).includes(elevId)) {
        delete this.scores[elevId];
        continue;
      }

      for (const oppgave of this.oppgaverDel1) {

        // Add empty string score if score for this oppgave in del 1 does not exist for this elevId
        if (!Object.keys(this.scores[elevId].del1).includes(oppgave.id + '')) {
          this.scores[elevId].del1[oppgave.id] = '';
          continue;
        }

        if(this.scores[elevId].del1[oppgave.id] != '') {
          this.scores[elevId].del1[oppgave.id] -= 0;
        }

        if(this.scores[elevId].del1[oppgave.id] > oppgave.maxPoints){
          this.scores[elevId].del1[oppgave.id] = oppgave.maxPoints;
        }

      }

      for (const oppgave of this.oppgaverDel2) {

        // Add 0 score if score for this oppgave in del 2 does not exist for this elevId
        if (!Object.keys(this.scores[elevId].del2).includes(oppgave.id + '')) {
          this.scores[elevId].del2[oppgave.id] = '';
          continue;
        }

        if(this.scores[elevId].del2[oppgave.id] != '') {
          this.scores[elevId].del2[oppgave.id] -= 0;
        }


        if(this.scores[elevId].del2[oppgave.id] > oppgave.maxPoints){
          this.scores[elevId].del2[oppgave.id] = oppgave.maxPoints;
        }
      }

      for (const oppgaveId of Object.keys(this.scores[elevId].del1)) {
        if (!oppgaveDel1IdArray.includes(parseInt(oppgaveId, 10))) {
          delete this.scores[elevId].del1[oppgaveId];
        }
      }

      for (const oppgaveId of Object.keys(this.scores[elevId].del2)) {
        if (!oppgaveDel2IdArray.includes(parseInt(oppgaveId, 10))) {
          delete this.scores[elevId].del2[oppgaveId];
        }
      }

    }
  }

  setElevCanView(elevId: number, canView: boolean) {
    if (canView) {
      if (this.openToElev.includes(elevId))
        return;
      this.openToElev.push(elevId);
    } else {
      if (this.openToElev.includes(elevId)) {
        for (let i = 0; i < this.openToElev.length; i++) {
          if (this.openToElev[i] == elevId) {
            this.openToElev.splice(i--, 1);
          }
        }
      }
    }
  }
}
