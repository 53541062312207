import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { KlasserService } from '../klasser.service';
import { ProverService } from 'src/app/prover/prover.service';
import { InstillingerService } from '../../instillinger/instillinger.service';

declare function $(obj: any);

@Component({
  selector: 'app-klasser',
  templateUrl: './klasser.component.html',
  styleUrls: ['./klasser.component.scss']
})
export class KlasserComponent implements OnInit {

  newName = '';
  aar = '';

  constructor(
    public klasser: KlasserService,
    public instillinger: InstillingerService,
    private prover: ProverService,
    private route: ActivatedRoute
  ) {
    route.params.subscribe(params => {
      this.aar = params.aar.replace('-', '/');
    });
  }

  ngOnInit() {
  }

  getKlasser() {
    return this.klasser.list
      .filter(klasse => klasse.year == this.aar);
  }

  // Function for opening the new-klasse-modal
  onOpenNewKlasse(): void {
    $('#add-klasse-modal').modal('show');
  }

  // Called by the button inside the new-klasse-modal
  onNewKlasse(): void {

    if (this.newName === '') {
      return;
    }

    this.klasser.newKlasse(this.newName);

    $('#add-klasse-modal').modal('hide');
    this.newName = '';

  }
}
