import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { firestore } from 'firebase';
import { ReadLine } from 'readline';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  displayName = '';
  isLoggedIn = false;
  uid = '';
  user$: Observable<firebase.User>;
  userDoc: ReplaySubject<User> = new ReplaySubject(1);

  constructor(
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router
  ) {

    // Make an observable for user, so other services can subscribe for user change
    this.user$ = this.fireAuth.authState.pipe(
      map(user => {
        if (!user) {
          return null;
        }
        return user;
      })
    );

    // Subscribe to the observable and edit buffered info
    this.user$.subscribe(user => {

      if (user === null) {
        this.isLoggedIn = false;
        this.displayName = '';
        this.uid = '';
        this.userDoc.next(null);
        return;
      }

      this.isLoggedIn = true;
      this.displayName = user.displayName ? user.displayName : user.email;
      this.uid = user.uid;
      this.firestore.doc(`/users/${this.uid}`).get().subscribe(doc => {
        if (doc.exists)
          this.userDoc.next(new User(doc.id, doc.data()));
        else
          this.userDoc.next(null);
      });
    });

  }

  // Check if user is logged inn
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.fireAuth.authState,
      this.userDoc
    ]).pipe(
      first(),
      map(([authState, userDoc]) => {
        if (!authState) {
          this.router.navigate(['/', 'login']);
          return false;
        }
        let path = route.url.reduce((path, e) => `${path}/${e.path}`, '');
        if (userDoc === null && path !== '/registration/complete') {
          this.router.navigate(['/', 'registration', 'complete']);
          return false;
        }
        return true;
      })
    );
  }

  // Call this function to logg inn
  async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    let result = await this.fireAuth.auth.signInWithEmailAndPassword(email, password);
    this.userDoc.complete();
    this.userDoc = new ReplaySubject(1);
    return result;
  }

  // Call this function to logg out
  logout(): void {
    this.fireAuth.auth.signOut();
  }
}
