<button type="button" class="btn btn-success" (click)="onOpenSettings()">
  Instillinger for prøven
</button>

<!-- Modal -->
<div class="modal fade" id="prove-settings-modal" tabindex="-1" role="dialog" *ngIf="prove">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Instillinger for prøven</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Lukk">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h1 class="mb-3">Oppgaver Del 1</h1>

        <app-spread-sheet [getData]="getOppgaveDataFunctionDel1()" prefix="oppgaver_del1" #proveOppgaverDel1>
        </app-spread-sheet>

        <h1 class="mb-3 mt-3">Oppgaver Del 2</h1>

        <app-spread-sheet [getData]="getOppgaveDataFunctionDel2()" prefix="oppgaver_del2" #proveOppgaverDel2>
        </app-spread-sheet>

        <h1 class="mt-3 mb-3">Egenevalueringsoppgaver:</h1>

        <div class="row mt-3" *ngFor="let sporsmaal of prove.egenvurdering; let i = index">
          <div class="col-11">
            <textarea [(ngModel)]="sporsmaal.text"></textarea>
          </div>
          <div class="col-1">
            <i class="fa fa-minus-circle mt-2 fa-2x text-danger" style="cursor: pointer;"
              (click)="dropSporsmaal(i)"></i>
          </div>
        </div>

        <i class="fa fa-plus-circle mt-2 fa-2x text-success" style="cursor: pointer;" (click)="addSporsmaal()"></i>

        <h3 class="mt-3">Egenretting: <div class="dropdown d-inline">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ prove.egenretting ? 'Ja' : 'Nei' }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="setEgenretting(true)">Ja</a>
            <a class="dropdown-item" (click)="setEgenretting(false)">Nei</a>
          </div>
        </div></h3>

        <h1 class="mt-3 mb-3">Karakterskala:</h1>

        <div class="row mt-4">
          <div class="col-md-6 col-12">
            <div class="input-group" *ngFor="let karakter of karaktererRow1">
              <div class="input-group-prepend">
                <span class="input-group-text karakter-span">
                  {{karakter}}:
                </span>
              </div>
              <input class="form-control" type="number" [placeholder]="prover.getKarakterskala()[karakter]"
                [(ngModel)]="prove.karakterskala[karakter]">
              <div class="input-group-append">
                <span class="input-group-text">
                  %
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="input-group" *ngFor="let karakter of karaktererRow2">
              <div class="input-group-prepend">
                <span class="input-group-text karakter-span">
                  {{karakter}}:
                </span>
              </div>
              <input class="form-control" type="number" [placeholder]="prover.getKarakterskala()[karakter]"
                [(ngModel)]="prove.karakterskala[karakter]">
              <div class="input-group-append">
                <span class="input-group-text">
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
