import { Injectable } from "@angular/core";
import { KlasserService } from "../klasser/klasser.service";
import { Subscription } from "rxjs";
import { Klasse } from "../models/klasse";
import { ProverService } from "../prover/prover.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class ElevService {
  klasseSubscription: Subscription = null;
  klasse: Klasse = null;
  elevId: number = 0;
  name: string = "";

  constructor(
    private klasser: KlasserService,
    private prover: ProverService,
    private db: AngularFirestore
  ) {}

  use(klasseId, elevId): Promise<any> {
    this.clear();

    return new Promise((resolve, reject) => {
      this.klasseSubscription = this.klasser
        .klasse$(klasseId)
        .subscribe((klasse) => {
          if (!klasse && this.klasse) {
            this.clear();
            return;
          }

          // Dont update if klasse is already set and new klasse is not null
          if (klasse && !this.klasse) {
            if (Object.keys(klasse.students).includes(elevId)) {
              this.klasse = klasse;
              this.prover.setKlasse(klasse).then(resolve);
              this.elevId = Number(elevId);
              this.name = klasse.students[elevId];
            } else {
              alert("Unknown elev, please contact support");
            }
          }
        });
    });
  }

  clear() {
    if (this.klasseSubscription) {
      this.klasseSubscription.unsubscribe();
      this.klasseSubscription = null;
    }

    this.klasse = null;
    this.prover.setKlasse(null);
    this.elevId = 0;
  }

  saveName() {
    this.klasse.students[this.elevId] = this.name;
    this.klasser.updateStudents(this.klasse);
  }

}
