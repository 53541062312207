

export function toNum(val: any): number {
  if (typeof val !== 'number') {
    val = Number(val);
  }
  if (val !== val) {
    return 0;
  }
  return val;
}