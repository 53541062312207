import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  password = '';
  email = '';
  loading = false;

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onLogin(): void {
    this.loading = true;
    this.auth.login(this.email, this.password)
      .then(() => {
        this.loading = false;
        this.router.navigate(['/']);
      })
      .catch(err => {
        this.loading = false;
        alert(err.message);
      });
  }
}
