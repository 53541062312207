<div class="container pt-3">
  <button (click)="onBack()" class="btn btn-primary mb-3">Tilbake</button>
  <h1>Årssummering:</h1>

  <!-- LOADING SPINNER -->
  <div *ngIf="klasse === undefined" class="ra-spinner mt-3">
    <i class="fa fa-refresh fa-3x fa-spin" aria-hidden="true"></i>
  </div>

  <div *ngIf="klasse === null" class="alert alert-danger mt-3" role="alert">
    Du har ikke tilgang til denne klassen
  </div>

</div>

<div *ngIf="!!klasse" class="pt-3">
  <div class="d-flex justify-content-center">
    <table>
      <tr>
        <td></td>
        <th scope="col" class="pl-3 pr-3 pt-2 pb-2" *ngFor="let prove of proverList">{{prove.name}}</th>
        <th class="pl-3 pr-3 pt-2 pb-2">Halvårsvurdering</th>
        <th class="pl-3 pr-3 pt-2 pb-2">Sluttvurdering</th>
        <th class="pl-3 pr-3 pt-2 pb-2">Eksamenskarakter</th>
      </tr>
      <tr *ngFor="let student of students;">
        <th scope="row" class="pl-2 pr-2">{{student.name}}</th>
        <td *ngFor="let prove of proverList" class="prove-karakter">{{getKarakter(prove, student.id)}}</td>
        <td><input type="text" (focusout)="save()" [(ngModel)]="klasse.halvaarsvurdering[student.id]"></td>
        <td><input type="text" (focusout)="save()" [(ngModel)]="klasse.sluttvurdering[student.id]"></td>
        <td><input type="text" (focusout)="save()" [(ngModel)]="klasse.eksamenskarakter[student.id]"></td>
      </tr>
      <tr>
        <th scope="row" [attr.colspan]="1 + proverList.length" class="text-right pr-2 pl-2">Gjennomsnitt:</th>
        <td>{{getAvgHalvaar()}}</td>
        <td>{{getAvgSlutt()}}</td>
        <td>{{getAvgEksamen()}}</td>
      </tr>
    </table>
  </div>
</div>
