
<div class="container">

<div class="row mt-4">
  <div class="col-lg-4 col-sm-3"></div>
  <div class="col-lg-4 col-sm-6  col-12">

    <h1>Velg år</h1>


    <!-- AAR -->
    <div class="ra-klasser" *ngIf="instillinger.global.years">
      <div 
        class="ra-shaddow-box" 
        *ngFor="let year of instillinger.global.years" 
        [routerLink]="[year.replace('/','-'), 'klasser']">
        {{year}}
      </div>
    </div>

  </div>
</div>


</div>