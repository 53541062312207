import { Injectable } from '@angular/core';
import { Prove } from '../models/prove';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore'; import { Klasse } from '../models/klasse'; import { map } from 'rxjs/operators';
import { InstillingerService } from '../instillinger/instillinger.service';

declare function fromEntries(...args): any;

@Injectable({
  providedIn: 'root'
})
export class ProverService {

  loading = true;
  list: Prove[] = [];
  private klasse: Klasse; private proveSubject = new BehaviorSubject<Prove[]>(undefined); private proverSubscription: Subscription; private proverCollection: AngularFirestoreCollection;

  constructor(
    private db: AngularFirestore,
    private instillinger: InstillingerService
  ) { }

  // Update what klasse the service should look for prover in NB: klasse = null will set the service in loading mode
  setKlasse(klasse: Klasse): Promise<any> {

    if (klasse === this.klasse) {
      return new Promise((res, rej) => res(null));
    }

    // Clear current firestore connection data
    this.loading = true;
    this.list.splice(0, this.list.length);

    if (this.proverSubscription) {
      this.proverSubscription.unsubscribe();
      this.proverSubscription = undefined;
    }

    if (this.proverCollection) {
      this.proverCollection = undefined;
    }

    this.proveSubject.next(undefined);

    // Set this.klasse to klasse and return if klasse == null || undefined
    this.klasse = klasse;
    if (!klasse) {
      return new Promise((res, rej) => res(null));
    }

    // Update firestore connection and subscriptions
    this.proverCollection = this.db.collection(`/klasser/${klasse.id}/prover`);

    let first = true;

    return new Promise((resolve, reject) => {
      this.proverSubscription = this.proverCollection.stateChanges()
        .subscribe(snaps => {
          this.stateChanges(snaps);
          if (first) {
            first = false;
            resolve(null);
          }
        });
    });
  }


  // State change callback for firestore
  private stateChanges(snaps) {

    // Looping trough the snaps
    for (const snap of snaps) {

      // Adds new prove to prove-array, if type == added
      if (snap.type === 'added') {

        this.list.push(
          new Prove({
            id: snap.payload.doc.id,
            klasse: this.klasse,
            ...snap.payload.doc.data()
          })
        );

        // Updates prove, if type == modified
      } else if (snap.type === 'modified') {

        for (const prove of this.list) {
          if (prove.id === snap.payload.doc.id) {
            prove.update(snap.payload.doc.data());
            break;
          }
        }

        // Removes prove from prove-array, if type == removed
      } else if (snap.type === 'removed') {
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].id === snap.payload.doc.id) {
            this.list.splice(i, 1);
            break;
          }
        }
      }

    }

    this.proveSubject.next(this.list);
    this.loading = false;
  }

  // Create subscription on a spicific prove
  prove$(id: string): Observable<Prove> {
    return this.proveSubject.pipe(
      map((list): Prove => {

        // Return undefined if the service is currently loading prover
        if (list === undefined) {
          return undefined;
        }

        // Loop trough the list of prover
        for (const prove of list) {
          if (prove.id === id) {
            return prove;
          }
        }

        // Return null if the prove does not exist
        return null;
      })
    );
  }

  // Add new prove to the current collection
  newProve(name: string) {

    // Return if collection is undefined or name is undefined
    if (!this.proverCollection || !name) {
      return;
    }

    this.proverCollection.add({ name });
  }

  deleteProve(klasse: Klasse, prove: Prove) {

    if (!klasse || !prove) {
      return;
    }

    this.db.doc(`klasser/${klasse.id}/prover/${prove.id}`).delete();
  }

  // Update the name of a certain prove
  pushChanges(prove: Prove): Promise<any> {

    const obj = {
      name: prove.name,
      oppgaverDel1: prove.oppgaverDel1,
      oppgaverDel2: prove.oppgaverDel2,
      nextOppgaveIdDel1: prove.nextOppgaveIdDel1,
      nextOppgaveIdDel2: prove.nextOppgaveIdDel2,
      karakterer: prove.karakterer,
      karakterskala: prove.karakterskala,
      kommentar: prove.kommentar,
      scores: prove.scores,
      egenvurdering: prove.egenvurdering,
      egenretting: prove.egenretting,
      studentLock: prove.studentLock,
      openToElev: prove.openToElev
    };

    return this.db.doc(`klasser/${prove.klasse.id}/prover/${prove.id}`)
      .update(obj);
  }

  getKarakterskala(prove?: Prove) {
    if (!prove || !(prove.karakterskala))
      return this.instillinger.getKarakterskala();

    let result = Object.assign(
      this.instillinger.getKarakterskala(),
      fromEntries(Object.entries(prove.karakterskala).filter(e => e[1] === 0 || !!e[1]))
    );

    return result;
  }

  delMedElev(prove: Prove, elevId) {
    prove.setElevCanView(elevId, true);
  }
}
